import * as React from 'react';
import { useState, useEffect } from 'react';
import { useContent } from '../../../Content/cms';

import Apirest from '../../../Content/Apirest';

import { Survey } from '../types';
import { faFile } from "@fortawesome/free-solid-svg-icons";
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import { IconButton } from "../../../Commons/MultipleComponents";
import SurveyForm from './Form';


interface SurveyFormProps {
    participant_id: string,
    onlyIcon?: boolean,
    align?: string
}

const Surveys : React.FC<SurveyFormProps> = (props) => {

    let getContent = useContent();

    let [surveys, setSurveys] = useState<Survey[]>([]);

    let [currentSurvey, setCurrentSurvey] = useState<Survey|undefined>();

    let [show, setShow] = useState<boolean>(false);

    useEffect(
        () => {
            Apirest.get_surveys((surveys: Survey[]) => {
                setSurveys(surveys);
                if (surveys.length > 0) {
                    setCurrentSurvey(surveys[0]);
                }
            });
        }, [props.participant_id]
    )

    function changeSelectedSurvey(event: React.ChangeEvent<HTMLSelectElement>) {
        setCurrentSurvey(surveys[event.target.selectedIndex]);
    }

    return (
        <Container className={props.align}>
            <button
                className={'ml-0 mb-0 px-3 text-btn bg-transparent no-border'}
                onClick={() => setShow(true)}
            >
                <IconButton
                    text={getContent("survey__open__icon_tooltip")}
                    icon={{icon:faFile,color:"#5CB6E7"}}
                />
                {!props.onlyIcon && ' ' + getContent("survey__open__text")}
            </button>
            <Modal show={show} onHide={() => setShow(false)} size={'lg'} backdrop={'static'}>
                <Modal.Header closeButton>
                    <Col md={10}>
                    <Form.Group controlId="formFileMultiple" className="mb-3">
                    <Form.Label>{getContent("survey__select__label")}</Form.Label>
                    <Form.Control
                        size={'sm'}
                        as={'select'}
                        onChange={changeSelectedSurvey}
                        value={currentSurvey ? currentSurvey.id : undefined}
                    >
                        {surveys.map(s => (
                            <option key={s.id} value={s.id}>{s.name}</option>
                        ))}
                    </Form.Control>
                    </Form.Group>
                    </Col>

                </Modal.Header>
                <Modal.Body>
                    {currentSurvey && <SurveyForm participant_id={props.participant_id} {...currentSurvey} />}
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default Surveys;
