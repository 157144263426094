import React, { FC, ReactNode, useRef, useState, useEffect } from 'react';
import { useContent } from '../../../Content/cms';
import MicRecorder from './mic_recorder';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

export interface RecorderProps {
  onRecordingStart?: () => void;
  onRecordingStop?: (data: Blob) => void;
  sendToSigmind?: boolean;
  disabled?: boolean;
  minDuration?: number;
}

// TODO: check if I can make a signal visual effect when recording
const Recorder:FC<RecorderProps> = (props) => {

  let getContent = useContent();

  // The recorder needs user permission to work, as this is asynchronous,
  // we use a state to indicate when the recorder is ready.
  // This is needed because the recorder is not a react component 
  // and therefore cannot trigger a state change.
  let [recorderReady, setRecorderReady] = useState(false);

  // This state is used to render correct state depending on the recorder state
  // because the recorder does not automatically trigger a state change in react.
  let [recording, setRecording] = useState(false);

  // This state enable stop recording after the min duration
  let [stopEnabled, setStopEnabled] = useState(!props.minDuration);

  // The recorder is store in a ref to be able to maintain it across renders.
  let recorder = useRef<MicRecorder>();
  useEffect(
    () => {
      recorder.current = new MicRecorder(true, () => setRecorderReady(true))
    }, []
  );
  
  // This function is called when the user stop recording to reset recording 
  // state for the next recording.
  useEffect(
    () => {
      if (!recording) {
        setStopEnabled(!props.minDuration);
      }
    }, [recording, props.minDuration]
  );

  // Toggle start/stop recording
  const toggleRecorder = () => setRecording(isRecording => {

    // If the recorder is not ready, we do nothing
    if (recorder.current === undefined) {
        throw new Error('Recorder is not ready');
    }
    
    if (isRecording) {
        // Stop recording and set obtained blob if it's currently recording
        recorder.current.stopRecording(props.onRecordingStop);
        return false;
    } else {
        // Start recording if not
        recorder.current.startRecording();
        if (props.onRecordingStart !== undefined) {
            props.onRecordingStart();
        }
        return true;
    }
  });

  // Render start or stop button depending on the current recording state.
  let button_txt: ReactNode = (recording) 
    ? <RecordingClock 
        timer={props.minDuration}
        onTimerEnd={() => {setStopEnabled(true); console.log('timer end')}}
      />
    : getContent('protocol__recorder__record');
  
  let style = (recording) ? ' recording' : '';

  // render a button to start recording
  return (
    <Button
      className={'recorder-button' + style}
      onClick={toggleRecorder}
      disabled={props.disabled || !recorderReady || (recording && !stopEnabled)}
      block
    >{button_txt}</Button>
  );
};

interface RecordingClockProps {
  timer?: number;
  onTimerEnd?: () => void;
}

const RecordingClock:FC<RecordingClockProps> = (props) => {
    let [time, setTime] = useState(0);
    let interval = useRef<number>();

    const onTimerEnd = props.onTimerEnd || (() => {});
    
    useEffect(
        () => {
            interval.current = window.setInterval(() => {
                setTime(currTime => currTime + 1);
            }, 1000);
            
            return () => {
                window.clearInterval(interval.current);
            };
        }, []
    );

    // When timer is over, trigger onTimerEnd
    useEffect(
        () => {
            if (time === props.timer) {
                onTimerEnd()
            }
        },
        [time] // eslint-disable-line react-hooks/exhaustive-deps
    )

    // Print seconds in a nice format
    let seconds = time % 60;
    let minutes = Math.floor(time / 60);
    let seconds_str = seconds < 10 ? '0' + seconds : seconds.toString();
    let minutes_str = minutes < 10 ? '0' + minutes : minutes.toString();
    return (
        <Container className={'recording-clock'} fluid>
            {minutes_str}:{seconds_str}
        </Container>
    );
};

export default Recorder;