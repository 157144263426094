import React from "react";

// Import components
import {Container, Row, Col} from "react-bootstrap";

//Import Styles
import "./index.css"

const LoaderSpinner: React.FC = () => (
    <Container fluid className={'bg-light full-height-container'}>
        <Row>
            <Col className={'text-center'}>
            <div className={'spinner-container'}>
                <img src="/logo_tell_header.svg" alt="loading" className={'logo-spinner'}/>
            </div>
            </Col>
        </Row>
    </Container>
);

export default LoaderSpinner;
