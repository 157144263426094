import * as React from "react";
import {Pagination} from "react-bootstrap";

interface DataFramePaginationProps {
    cpn: number
    totalPages: number
    setCPN: (pn: number | ((n: number) => number)) => void
}

const DataFramePagination: React.FC<DataFramePaginationProps> = (props) => {
    let {cpn, totalPages, setCPN} = props;

    if (totalPages < 2) return null;

    // Create the page indexes
    let pageNumbers = [];
    for (let i = 0; i < totalPages; i++) {
        pageNumbers.push(i);
    }

    // For less than 5 pages it display the full index
    if (totalPages > 1 && totalPages < 5) {
        return (
            <Pagination>
                {pageNumbers.map((idx) =>
                    <Pagination.Item key={idx} onClick={() => setCPN(idx)} active={idx === cpn}>
                        {idx + 1}
                    </Pagination.Item>
                )}
            </Pagination>
        );
    }

    // For more than 4 pages it shows a dynamic paginator
    return (
        <Pagination>
            <Pagination.First onClick={() => setCPN(0)}/>
            <Pagination.Prev onClick={() => setCPN(cp => Math.max(0, cp - 1))}/>

            {cpn > 0 && <Pagination.Ellipsis/>}
            {cpn === (totalPages - 1) && <Pagination.Item onClick={() => setCPN(cpn - 1)}>{cpn}</Pagination.Item>}

            <Pagination.Item  active>{cpn + 1}</Pagination.Item>

            {cpn === 0 && <Pagination.Item onClick={() => setCPN(1)}>2</Pagination.Item>}
            {cpn < (totalPages - 1) && <Pagination.Ellipsis/>}

            <Pagination.Next onClick={() => setCPN(cp => Math.min(totalPages - 1, cp + 1))}/>
            <Pagination.Last onClick={() => setCPN(totalPages - 1)}/>
        </Pagination>
    );
};

export default DataFramePagination;
