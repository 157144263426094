import React from "react";
import { Switch, Route } from 'react-router-dom'

import ProtectedRoutes from "./ProtectedRoutes";
import DataLoader from "./DataLoader";
import StyleLoader from "../Commons/StyleLoader";
import I18NProvider from "./I18NProvider";
import Footer from '../Commons/Components/Footer';
import Header from './Header';
import SiteMap from "../Commons/SiteMap";
import NotFound from '../NotFound';

// Import css Styles
import './index.css';

const Dashboard : React.FC = () => (
    <ProtectedRoutes>
        <DataLoader>
            <StyleLoader />
            <I18NProvider>
                <div className={'top-wrapper'} >
                <div className={'content-wrapper'}>
                    <Header />
                    <Switch>
                        {SiteMap.getDashboardRoutingMap()}
                        <Route path="/dashboard/" component={NotFound} />
                    </Switch>
                    <Footer />
                </div>
            </div>
            </I18NProvider>
        </DataLoader>
    </ProtectedRoutes>
);

export default Dashboard;