import * as React from "react";
import SiteMap from "./index";
import {Params} from "./index";

// Import components
import {Link as RouterLink} from "react-router-dom";

interface LinkProps {
    to: string
    onClick?: () => void
    params?: Params
    className?: string
}

const Link : React.FC<LinkProps> = (props) => {
    let {to, params, className} = props;
    return (
        <RouterLink 
            to={SiteMap.getFullURL(to,params)}
            className={className}
            onClick={props.onClick}
        >
            {props.children}
        </RouterLink>
    );
};

export default Link;