import React from "react";
import { useState } from 'react';
import Apirest from "../../Content/Apirest";
import { useLocalContent } from "../GetLanguageAuth/useLocalContent";

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from  'react-bootstrap/Row';
import Col from  'react-bootstrap/Col';
import { Spinner } from "react-bootstrap";
import { PopUpSuccess } from "../../Commons/Helpers";
import AuthLanguageForm from "../GetLanguageAuth"

const SetPassword: React.FC = () => {


    let getContent = useLocalContent();

    let [email, setEmail] = useState<string>('');
    let [sendingEmail, setSendingEmail] = useState<boolean>(false);

    const handleInput = (event: any) => {
        const { value } = event.target;
        setEmail(value);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setSendingEmail(true);

        Apirest.send_reset_password_email(
            email,
            () => {
                PopUpSuccess(getContent('authentication__reset_password_success'));
                setSendingEmail(false);
            }
        )
    };

    return (
        <Container fluid className={'deg-bg vh-100'}>
            <Row className={'d-flex justify-content-center text-center d-flex align-items-center pt-5'}>
                <Col sm={6}>
                    <Card className={'bg-light w-75 m-auto'}>
                        <Card.Body>
                                <Card.Title className={'m-auto pb-3'}>
                                <img 
                                src="/logo_tell_header.svg"
                                alt={'logo'}
                                className={'align-self-center p-3 mb-2'}
                            />
                                    <p><small>{getContent('authentication__reset_password_instructions')}</small></p>
                                </Card.Title>
                                <Card.Text>
                                    <Form className={'text-left'} onSubmit={handleSubmit}>
                                        <Form.Group >
                                            <Form.Control 
                                                as={'input'}
                                                name={'email'}
                                                type={'email'}
                                                value={email}
                                                onChange={handleInput}
                                                placeholder={getContent('authentication__reset_password_placeholder_email')}
                                                required
                                            />
                                        </Form.Group>
                                        <Button type={'submit'} disabled={sendingEmail} className={'w-100'}>
                                            {!sendingEmail && <p className={'p-0 mb-0'}>{getContent('authentication__reset_password_reset_button')}</p>}
                                            {sendingEmail && <Spinner animation="border" variant="primary" />}
                                        </Button>
                                    </Form>
                                </Card.Text>
                        </Card.Body>
                    </Card>
                    <AuthLanguageForm />
                </Col>
            </Row>
    </Container>
    );
};

export default SetPassword;