import * as React from 'react';
import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PopUpSuccess, PopUpError } from '../../Commons/Helpers';
import { useContent } from '../../Content/cms';
import { useDirtyState } from '../../store/dirtyState';
import SiteMap from '../../Commons/SiteMap';

import Apirest from "../../Content/Apirest";

import { Item, ProtocolData } from './types';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProtocolItems from './ProtocolItems';
import ParticipantHeader from '../../Commons/Components/Participant/Header';
import { RowSpinner } from '../../Commons/MultipleComponents';
import Section from "../../Commons/Section";

import './index.css';


const Recorder : React.FC = () => {

    let history = useHistory();

    let getContent = useContent();

    let {setDirty, setClean} = useDirtyState('protocol_recorder');

    let {participant_id} = useParams<{participant_id: string}>();

    let [protocolResID, setProtocolResID] = useState<number|undefined>();

    let [protocolFinished, setProtocolFinished] = useState<Boolean>(false);

    let [availableProtocols, setAvailableProtocols] = useState<ProtocolData[]|undefined>();

    let [savedResponses, setSavedResponses] = useState<string[]>([]);

    // Store the protocol items data
    let [selectedProtocol, setSelectedProtocol] = useState<ProtocolData|undefined>();

    // get available protocols from server
    useEffect(() => {Apirest.get_protocols(setAvailableProtocols)}, []);

    useEffect(checkAllSavedAndRedirect, [savedResponses, protocolFinished]);

    function checkAllSavedAndRedirect() {
        if (selectedProtocol !== undefined && protocolFinished) {
            
            let all_saved = selectedProtocol.items.every(
                item => savedResponses.indexOf(item.id.toString()) > -1
            );
    
            if (all_saved) {
                setClean();
                PopUpSuccess(getContent('recorder__server_sync__success'));
                setTimeout(
                    () => history.push(SiteMap.getFullURL('participant', [participant_id]))
                    , 1000
                );
            }
        }
    }

    function onProtocolEnd() {
        setProtocolFinished(true);
    }

    const onProtocolStart = setDirty;

    function sendResponse(protocol_item_id: string, blob: Blob) {
        if (selectedProtocol === undefined) {
            throw (new Error("Protocol data is undefined"));
        }

        if (protocolResID === undefined) {
            throw (new Error("Protocol response ID is undefined"));
        }

        Apirest.send_protocol_item_response(
            participant_id,
            protocolResID.toString(),
            protocol_item_id,
            blob,
            () => setSavedResponses(prev => [...prev, protocol_item_id]),
            () => {

                // Note: In order to read the real value of the protocolFinished
                // state, we need to use the functional setState function to get
                // the actual prev state and return that same state to avoid
                // setting a new state.
                setProtocolFinished(prev => {
                    if (prev) {
                        PopUpError(getContent('recorder__server_sync__failed'));
                    }

                    return prev;
                })
                
                setTimeout(() => sendResponse(protocol_item_id, blob), 4000);
            }
        );
    }

    function onRecordingStop(blob: Blob, item: Item) {
        sendResponse(item.id.toString(), blob);
    }

    function onSelectProtocol(e: React.ChangeEvent<HTMLSelectElement>) {
        if (availableProtocols === undefined) {
            throw (new Error("Available protocols is undefined"));
        }

        let selectedProtocol = availableProtocols[e.target.selectedIndex-1];

        if (selectedProtocol === undefined) {
            throw (new Error("Selected protocol is undefined"));
        }

        Apirest.create_protocol_res(
            participant_id,
            selectedProtocol.id,
            (response: {id:number}) => setProtocolResID(response.id)
        )
        setSelectedProtocol(selectedProtocol);  
    }

    let content = null;

    // Protocol is on progress
    if (selectedProtocol !== undefined && !protocolFinished) {
        content = <ProtocolItems
            onProtocolEnd={onProtocolEnd}
            onProtocolStart={onProtocolStart}
            onRecordingStop={onRecordingStop}
            protocol={selectedProtocol}
        />;
    
    // Protocol is not yet selected
    } else if (selectedProtocol === undefined && availableProtocols !== undefined) { 
        content = (
            <Container className={'protocol'} fluid>
                <h3 className='protocol-header'>
                    {getContent('protocol__select_protocol__title')}
                </h3>
                <Row className={'protcol-body'}>
                    <Col>
                        <select 
                            className="protocol-select" 
                            defaultValue={-1} 
                            onChange={onSelectProtocol}
                        >
                            <option disabled value={-1}>
                                {getContent('protocol__select_protocol__title')}
                            </option>
                            {availableProtocols.map(
                                (protocol, idx) => 
                                    <option
                                        key={idx}
                                        value={idx}
                                    >{protocol.name}</option>
                            )}
                        </select>
                    </Col>
                </Row>
            </Container>
        );
    
    // Protocol is finished or protocol list not yet available
    } else {
        content = <Section
            className={'primary-section'}
            headerClassName={'primary-section-header'}
            bodyClassName={'primary-section-body'}
        >
            <RowSpinner className={'mt-3'} show/>
        </Section>;
    }

    return (
        <Container className={'bg-light content-wrapper'} fluid>
            <ParticipantHeader fetch_data />
            <Row className={'mx-3 d-flex justify-content-center'}>
                <Col lg={10}>
                    {content}
                </Col>
            </Row>
        </Container>
    )
}

export default Recorder;
