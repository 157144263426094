import {Examiner} from "../../Dashboard/interfaces";
import { AllActionsTypes } from "../actions_all";

// The patients store action names
export const SET_MANAGED_EXAMINERS = 'SET_MANAGED_EXAMINERS';
export const ADD_OR_UPDATE_EXAMINER = 'ADD_OR_UPDATE_EXAMINER';
export const CLEAR_ADMIN_STATE = 'CLEAR_ADMIN_STATE';

// The patients store actions interfaces
interface addExaminersAction {
    type: typeof SET_MANAGED_EXAMINERS
    payload: Examiner[]
}

// The patients store actions interfaces
interface addOrUpdateExaminerAction {
    type: typeof ADD_OR_UPDATE_EXAMINER
    payload: Examiner
}

// The patients store actions interfaces
interface clearAdminAction {
    type: typeof CLEAR_ADMIN_STATE
}

// Export the final patients store action states as a single type
export type AdminActionTypes =
    | addExaminersAction
    | addOrUpdateExaminerAction
    | clearAdminAction
    | AllActionsTypes;