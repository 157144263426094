import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useContent } from '../../Content/cms';

// Import JSX Components
import {Container, Row, Col} from "react-bootstrap";
import Index from './Form';
import ParticipantsList from "./ParticipantsList";
import ExaminerHeader from "../../Commons/Components/Examiner/Header";
import Joyride, { CallBackProps } from 'react-joyride';
import {registerParticipantSteps} from '../Tutorials'

import './index.css';
import { StoreState } from "../../store";
import { Examiner } from "../interfaces";

const Home: React.FC = () =>{

    let getContent = useContent();

    let [runRegisterPatient, setRunRegisterPatient] = useState<boolean>(false);
    let [stepIndex, setStepIndex] = useState<number>(0);

    let examiner = useSelector<StoreState, Examiner|null>(s => s.examiner);


    useEffect(
        () => {
            if (examiner !== null) {
                let ls_key = examiner.id.toString() + '_home_tutorial_viewed';
                let already_shown_tutorial = localStorage.getItem(ls_key);
                if (already_shown_tutorial !== 'true') {
                    localStorage.setItem(ls_key, 'true');
                    setRunRegisterPatient(true);
                }
            }
        },
        [examiner]
    );

    const runTutorial = () =>{
        setRunRegisterPatient(true)
    };

    const handleJoyrideCallback = (data: CallBackProps) => {
        if (data.type === 'step:after') {
            // Update state to advance the tour
            setStepIndex( data.index + (data.action === 'prev' ? -1 : 1) );
        } else if ((data.status === 'finished'|| data.status === "paused") || data.action === 'close') {
            // Need to set our running state to false, so we can restart if we click start again.
            setRunRegisterPatient(false);
            setStepIndex(0);
        }
    };

    return (
        <Container  fluid  className={'content-wrapper'}>
            <Joyride
                 run={runRegisterPatient}
                 steps={registerParticipantSteps(getContent)}
                 callback={handleJoyrideCallback}
                 debug={true}
                 continuous={true}
                 floaterProps ={{placement:'auto'}}
                 showProgress={true}
                 scrollOffset={150}
                 styles={{
                    options: {
                        beaconSize: 26,
                        primaryColor: '#5CB6E7'
                    }
                 }}
                stepIndex={stepIndex}

           />
           <Container className={'patients fluid d-flex-column bg-white '} fluid>
                <ExaminerHeader runTutorial={runTutorial} />
            </Container>
            <Row className={'content-home'}>
                <Col xs={12} md={8} className={'bg-white'}>
                    <ParticipantsList />
                </Col>
                <Col xs={12} md={4} className={'bg-light '}>
                    <Index />
                </Col>
            </Row>
        </Container>
    );
};


export default Home;
