import * as React from "react";
import { store } from '../store';
import { ADD_POP_UP_ALERT, popUpAlertVariant, REMOVE_POP_UP_ALERT } from "../store/popUpAlert/types";

import moment from "moment";

import CountryCodes from "../Data/CountryCodes.json";
import {
    Country,
    Examiner,
    Locale,
    Participant
} from "../Dashboard/interfaces";
import { 
    FormSubmitData,
    FieldJsonScheme,
    FieldScheme,
    FormFieldValue,
    CountryCode,
    Option,
    FormTelField
} from "./Components/FormFields/types";

import { getContentFunction } from "../Content/cms";

export const range = (start:number = 0, end:number, step:number = 1) : number[] => {
    let res = [];
    let item = start;
    while (item < end) {
        res.push(item);
        item += step;
    }

    return res;
};

export const defaultCountry = () : CountryCode => {
    let examiner = store.getState().examiner;
    let examinerCode = examiner === null ? 'AR' : examiner.locale.country;

    return CountryCodes.find(c => c.code === examinerCode)!;
};

export const str2FormTelField = (value: string) : FormTelField => {

    let country = CountryCodes.find(c => value!.startsWith(c.dial_code.toString())) || defaultCountry();
    let number = value.replace(country.dial_code.toString(),'');

    return {
        country: country,
        number: number
    };
};

export const participant2Values = (patient: Participant | undefined) : FormSubmitData => {
    let data : FormSubmitData = {};
    if (patient) {
        for (let [prop, value] of Object.entries(patient).filter(([,value]) => (value !== undefined && value !== null))) {
            switch (prop) {
                case 'country':
                    if (value !== '') {
                        data[prop] = (value as Country).code;
                    }
                    break;
                case 'mobile_phone':
                    data[prop] = str2FormTelField(value);
                    break;
                case 'locale':
                    data[prop] = (value as Locale).id.toString();
                    break;
                case 'birthday':
                case 'diagnose_date':
                    data[prop] = moment(value).format('YYYY-MM-DD');
                    break;
                default:
                    data[prop] = value;

            }
        }
    }
    return data;
};

export const examiner2Values = (examiner: Examiner | undefined) : FormSubmitData => {
    let data : FormSubmitData = {};
    if (examiner) {
        for (let [prop, value] of Object.entries(examiner)) {
            switch (prop) {
                case 'country':
                    data[prop] = value.code;
                    break;
                case 'protocols':
                    data[prop] = value.map((p:number) => p.toString());
                    break;
                case 'surveys':
                    data[prop] = value.map((s:number) => s.toString());
                    break;
                case 'mobile_phone':
                    data[prop] = str2FormTelField(value);
                    break;
                case 'locale':
                    data[prop] = value.id;
                    break;
                case 'birthday':
                    data[prop] = moment(value).format('YYYY-MM-DD');
                    break;
                default:
                    data[prop] = value;
            }
        }
    }
    return data;
};

export const getEmptyValue = (type: string) : FormFieldValue => {
    let country = defaultCountry();

    switch (type) {
        case 'multiselect':
            return [];
        case 'checkbox':
            return false;
        case 'tel':
            return {country: country, number: ""};
        default:
            return '';
    }
};

export function removeDuplicates<T>(arr: T[], key: (a:T) => any): T[] {
    return arr.filter((a,index) => arr.findIndex(b => key(b) === key(a)) === index);
}

export function capitalize(text: string) : string {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

export function date2timestamp(date: moment.MomentInput) : number {
    return moment(date).startOf('day').valueOf();
}

export function PopUpAlert(message: React.ReactNode, variant: popUpAlertVariant = "success", duration?: number) : void {
    let timestamp = new Date().getTime();

    store.dispatch({
        type: ADD_POP_UP_ALERT,
        payload: {
            timestamp: timestamp,
            text: message,
            variant: variant,
            duration: duration
        }
    });

    if (typeof duration === "number") {
        setTimeout(
            () => store.dispatch({
                type: REMOVE_POP_UP_ALERT,
                payload: timestamp
            }),
            duration
        );
    }
}

export function PopUpSuccess(message: React.ReactNode, duration: (number|undefined) = 6000) {
    PopUpAlert(message, 'success', duration);
}

export function PopUpError(message: React.ReactNode, duration: (number|undefined) = 6000) {
    PopUpAlert(message, 'danger', duration);
}

export function buildRegexExpession(query: string) {
    let specialChars = '.*+?^$';
    let regexExpresion = '^(?<remainder0>(.)*?)' + query
        .split('')
        .map((character,idx) => '(?<match' + (idx+1).toString() + '>' + (specialChars.indexOf(character) > -1 ? '\\' : '') + character + ')(?<remainder' + (idx+1).toString() + '>(.)*?)')
        .join('') + '$';

    return new RegExp(regexExpresion, 'i'); // Search case Insensitive (i)
}

export function builWordRegexExpession(query: string) {
    let specialChars = '.*+?^$'.split('');

    //Scape spetial characters
    for (let specialChar of specialChars) {
        query = query.replaceAll(specialChar, '\\' + specialChar);
    }


    let regexExpresion = '^(?<remainder0>(.)*?)' + query
        .split(' ')
        .map((word,idx) => '(?<match' + (idx+1).toString() + '>' + word + ')(?<remainder' + (idx+1).toString() + '>(.)*?)')
        .join('') + '$';

    return new RegExp(regexExpresion, 'is'); // Search case Insensitive (i) and allow multi-line string (s)
}

export function boldRegexMatch(regex: RegExp, value: string) : [boolean, React.ReactNode] {

    let regexResult = regex.exec(value);

    if (regex.test(value) && regexResult !== null && regexResult.groups !== undefined) {

        // sort alphanumerically with natural numeric order
        let collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
        let parsedData = Object.entries(regexResult.groups).sort((a,b) => collator.compare(a[0], b[0]));
        let matches = parsedData.filter(group => group[0].indexOf('match') > -1).map(group => group[1]);
        let remainders = parsedData.filter(group => group[0].indexOf('remainder') > -1).map(group => group[1]);

        return [
            true,
            <>
                {remainders[0]}
                {matches.map((match,idx) =>
                    <span key={idx.toString()}>
                        <b>{match}</b>{remainders[idx+1]}
                    </span>
                )}
            </>
        ];
    }

    // If regex does not match, return the original value without bolded parts
    return [false, <>{value}</>];
}

export function boldStringRegexMatch(regex: RegExp, value: string) : [boolean, string] {

    let regexResult = regex.exec(value);

    if (regex.test(value) && regexResult !== null && regexResult.groups !== undefined) {

        // sort alphanumerically with natural numeric order
        let collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
        let parsedData = Object.entries(regexResult.groups).sort((a,b) => collator.compare(a[0], b[0]));
        let matches = parsedData.filter(group => group[0].indexOf('match') > -1).map(group => group[1]);
        let remainders = parsedData.filter(group => group[0].indexOf('remainder') > -1).map(group => group[1]);

        return [
            true,
            remainders[0] +
            matches.map((match,idx) => '<b>' + match + '</b>' + remainders[idx+1]).join('')
        ];
    }

    // If regex does not match, return the original value without bolded parts
    return [false, value];
}

export function addOptions(field_name: string, options: Option[], schema: FieldScheme[]) : FieldScheme[] {
    return schema.map((field: FieldScheme) => {
        if (field.name === field_name) {
            return {
                ...field,
                options: options
            };
        }

        return field;
    });
}

export function setFormContent(getContent: getContentFunction, fieldsSchema: FieldJsonScheme[], prefix: string): FieldScheme[] {

    let fields = fieldsSchema.map(field => ({
        name: field.name,
        type: field.type,
        required: field.required,
        show: field.show,
        option_values: field.option_values,
        placeholder: field.placeholder ? getContent(prefix + '__placeholders__' + field.name) as string : undefined,
        label: field.label ? getContent(prefix + '__labels__' + field.name) as string : undefined,
        className: field.className ? field.className : '',
    }) as FieldScheme);

    // all fields of type select and a values list must get the values labels from content
    for (let field of fields) {
        if (field.type === 'select' && field.option_values !== undefined) {
            field.options = field.option_values.map(value => ({
                value: value,
                label: getContent(prefix + '__options_labels__' + field.name + '__' + value) as string
            }));
        }
    }

    return fields;
}
