import * as React from 'react'
import {useParams} from "react-router";
import {useContent} from '../../../../Content/cms';

// Import components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { NavDropdown, Row, Col } from 'react-bootstrap';
import Link from '../../../SiteMap/Link';
import FilesButton from "../../Files";
import { StoreState } from "../../../../store";
import { useSelector } from "react-redux";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "../../../MultipleComponents";


interface menuOwnProps {
    runTutorial?: () => void
}

const Menu :React.FC<menuOwnProps> = (props) => {

    let {runTutorial} = props;

    let getContent = useContent();

    let {participant_id} = useParams<{participant_id: string}>();
    let patient = useSelector((state: StoreState) => state.participants.find(p => p.id.toString() === participant_id));


    if (patient === undefined) return null;


    return (
        <NavDropdown
            title={<Row className={'pl-0 mb-1 pb-0'}>
                    <Col className={'pl-0 pt-0 pb-0 mb-0 '}>
                        <p className={'text-primary p-0 m-0 header-patient-name '}>  {patient.code}  <FontAwesomeIcon icon={faChevronDown} className={'mx-1 small-text'}/></p>
                    </Col>
                </Row>}
            id={"nav-dropdown"}
            className={'pl-3'}
            active
            >
                <NavDropdown.Item  as={'div'}>
                    <Link to={'edit_patient'} params={[participant_id]} className={'secondary-link'}>
                        {getContent("commons__participant__header__menu__edit_participant")}
                    </Link>
                </NavDropdown.Item>
                <NavDropdown.Item as={'div'}>
                    <FilesButton />
                </NavDropdown.Item>
                <NavDropdown.Item as={'div'} className={'d-none'}>
                    <button className={'mx-0 px-0 link-button bg-transparent no-border runRegisterPatientTutorial'} onClick={runTutorial}>{getContent("commons__participant__header__menu__see_tutorial")}<IconButton
                        text={getContent("commons__participant__header__menu__see_tutorial_tooltip")}
                        icon={{icon:faQuestionCircle, color:"#5CB6E7"}}
                    /></button>
                </NavDropdown.Item>
        </NavDropdown>
    );
};

export default Menu;
