import ReactGA from 'react-ga';
import $ from 'jquery';
import moment from 'moment';

import {store} from '../../store';

import config from "../../Config";

// Import interfaces
import { Method, SET_SERVER_ERROR } from "../../store/server/types";

interface QueryParams {
    [param: string]: (number|string)
}

export type ResponseCallback<T=any> = (response: T) => void;

export type FailCallback = (jqXHR: JQueryXHR, data: any, url: string, method: Method, textStatus: any, error: any) => void;

// Note: This function is not imported from session manager because of a circular dependencies problem
function logout() {
    console.log('%c LOGOUT', 'color:#ffc42e');
    //localStorage.removeItem('jwt_token');
    //localStorage.removeItem('jwt_refresh');
}

const SaveErrorToStore = (jqXHR: JQueryXHR, textStatus: any, error: any) => {
    console.log('ERROR', jqXHR, textStatus, error)
};

export function deepParseObjectTimestamps(obj: any): any {
    if (obj && typeof obj === 'object') {
        for (let key of Object.keys(obj)) {

            let value = obj[key];
            let isDateTimeFormattedString = (typeof value === 'string') && (
                moment(value, 'YYYY-MM-DD', true).isValid() ||
                moment(value, 'YYYY-MM-DDTHH:mm:ssZ', true).isValid() ||
                moment(value, 'YYYY-MM-DDTHH:mm:ss.SSSSZ', true).isValid()
            );

            if (isDateTimeFormattedString) {
                obj[key] = moment(value, moment.ISO_8601);
            } else if (typeof value === 'object') {
                deepParseObjectTimestamps(value);
            }
        }
    }
    return obj;
}

export default class ServerConnection {

    baseURL: string;

    constructor(baseURL: string) {
        this.baseURL = baseURL;
    }

    preprocessAndSuccess(path: string, method: Method, success: ResponseCallback) {
        let startTime = new Date().getTime();
        return (response: any) => {
            ReactGA.timing({
                category: 'Apirest',
                variable: path,
                value: new Date().getTime() - startTime,
                label: method
            });
            success(deepParseObjectTimestamps(response));
        }
    }

    failInterceptor(fail: FailCallback, url: string, method: Method, data: any = {}, fail_401: FailCallback = logout) {
        return (jqXHR: JQueryXHR, textStatus: any, error: any) => {
            if (jqXHR.status === 500) {
                store.dispatch({
                    type: SET_SERVER_ERROR,
                    payload: {error: error, url: url, method: method, data: data}
                });
            } else if (jqXHR.status === 401) {
                // Default is logout
                // fail_401(jqXHR, data, url, method, textStatus, error);
            } else {
                // fail(jqXHR, data, url, method, textStatus, error);
            }
        }
    }

    get(path: string, query: QueryParams = {}, success: ResponseCallback = () => {}, fail: FailCallback = SaveErrorToStore) {
        
        let authorization: string | undefined = undefined
        let jwt_token = localStorage.getItem('jwt_token')
        if (jwt_token !== null) {
            authorization = 'Bearer ' + jwt_token
        }
        
        return $.get({
            url: this.baseURL + path,
            data: query,
            dataType: 'json',
            headers: {'Authorization': authorization},
            xhrFields: {withCredentials: true}
        })
            .then(this.preprocessAndSuccess(path, 'GET', success))
            .fail(this.failInterceptor(fail, config.apirest.baseURL + path, 'GET', query))
            .then(() => {console.log('Finished get request for:', path)});
    }

    delete(path: string, success: ResponseCallback = () => {} , fail: FailCallback = SaveErrorToStore) {
        
        let authorization: string | undefined = undefined
        let jwt_token = localStorage.getItem('jwt_token')
        if (jwt_token !== null) {
            authorization = 'Bearer ' + jwt_token
        }
        
        return $.ajax(
            this.baseURL + path,
            {
                type: 'DELETE',
                headers: {'Authorization': authorization},
                xhrFields: {withCredentials: true}
            }
        )
            .then(this.preprocessAndSuccess(path, 'DELETE', success))
            .fail(this.failInterceptor(fail, config.apirest.baseURL + path, 'DELETE'))
            .then(() => {console.log('Finished get request for:', path)});
    }

    post(path: string, data: any, success: ResponseCallback = () => {} , fail: FailCallback = () => {}, fail_401: FailCallback = logout) {
        
        let authorization: string | undefined = undefined
        let jwt_token = localStorage.getItem('jwt_token')
        if (jwt_token !== null) {
            authorization = 'Bearer ' + jwt_token
        }

        return $.post({
            url: this.baseURL + path,
            data: JSON.stringify(data),
            contentType: 'application/json',
            headers: {'Authorization': authorization},
            xhrFields: {withCredentials: true}
        })
            .then(this.preprocessAndSuccess(path, 'POST', success))
            .fail(this.failInterceptor(fail, config.apirest.baseURL + path, 'POST', data, fail_401))
            .then(() => {console.log('Finished post request for:', path)});
    }

    post_files(path: string, datas: FormData[], success: ResponseCallback = () => {} , fail: FailCallback = () => {}, callback: () => void = () => {}) {
        
        let authorization: string | undefined = undefined
        let jwt_token = localStorage.getItem('jwt_token')
        if (jwt_token !== null) {
            authorization = 'Bearer ' + jwt_token
        }

        return $.when(...datas.map(
            data => $.post({
                url: this.baseURL + path,
                data: data,
                contentType: false,
                processData: false,
                headers: {'Authorization': authorization},
                xhrFields: {withCredentials: true},
                dataType: 'json',
                success: this.preprocessAndSuccess(path, 'POST', success),
                error: this.failInterceptor(fail, config.apirest.baseURL + path, 'POST', data)
            })
        ))
            .then(callback, callback) // final callback to tell all requests have finished
            .then(() => {console.log('Finished post requests for:', path)});
    }

    patch(path: string, data: any, success: ResponseCallback = () => {} , fail: FailCallback = () => {}) {
        
        let authorization: string | undefined = undefined
        let jwt_token = localStorage.getItem('jwt_token')
        if (jwt_token !== null) {
            authorization = 'Bearer ' + jwt_token
        }
        
        return $.ajax(
            this.baseURL + path,
            {
                type: 'PATCH',
                data: JSON.stringify(data), // Jquery specifies that json data must  manually be encoded as string: (Check data field in https://api.jquery.com/jquery.ajax/)
                contentType: 'application/json',
                headers: {'Authorization': authorization},
                xhrFields: {withCredentials: true}
            }
        )
            .then(this.preprocessAndSuccess(path, 'PATCH', success))
            .fail(this.failInterceptor(fail, config.apirest.baseURL + path, 'PATCH', data))
            .then(() => {console.log('Finished post request for:', path)});
    }
}
