import * as React from 'react';


const StyleLoader: React.FC = () => {


    return (
        <div> 
                <link 
                    rel="stylesheet"
                    type="text/css"
                    href={'/css/bs_variants_tell.css'} 
                />
        </div>
    );
};

export default StyleLoader;