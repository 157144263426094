import * as React from 'react';
import {useState, useEffect} from "react";
import Apirest from '../../Content/Apirest';
import { Moment } from 'moment';
import {useContent} from '../../Content/cms';


// import interfaces
import { useParams } from "react-router-dom";

// Import component

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFlask} from "@fortawesome/free-solid-svg-icons";
import Section from "../../Commons/Section";
import DataFrame from "../../Commons/Components/DataFrame";
import ParticipantHeader from '../../Commons/Components/Participant/Header';
import SurveyResponseView from '../Surveys/Response';
import EvolutionChart from './EvolutionChart';
import GoBackButton from '../../Commons/Components/GoBackButton';
import ProtocolLink from '../ProtocolRes/protocolresLink';

import { SurveyResponse } from '../Surveys/types';
import { ProtocolDataShort } from '../Protocol/types';

interface ProtocolResDataShort {
    id: number
    protocol: ProtocolDataShort
    date_created: Moment
    participant: number
}


const Participant : React.FC = () => {

    let getContent = useContent();
    const mobiles = require('is-mobile');
    let mobile = mobiles();

    
    let {participant_id} = useParams<{participant_id: string}>();

    let [surveyResponses, setSurveyResponses] = useState<SurveyResponse[]>([]);
    let [protocolResponses, setProtocolResponses] = useState<ProtocolResDataShort[]>([]);
    
    // Effect triggered only once after mounting (ComponentDidMount).
    useEffect(
        () => {
            Apirest.get_participant_all_protocolres(participant_id, setProtocolResponses);
            Apirest.get_participant_survey_responses(participant_id, setSurveyResponses);

            // clean up
            return () => {
                setProtocolResponses([]);
                setSurveyResponses([]);
            }
        },
        [participant_id] // eslint-disable-line react-hooks/exhaustive-deps
    );

    function protocol2row(protocol: ProtocolResDataShort) {
        
        return [
            protocol.date_created,
            <ProtocolLink id={protocol.id} date={protocol.date_created} protocolName={protocol.protocol.name} protocolCode={protocol.protocol.code} participantId={participant_id} ></ProtocolLink>        ]
    }

    function survey2row(survey: SurveyResponse) {
        let {date_created, id} = survey;
        return [
            date_created,
            <SurveyResponseView id={parseInt(id)} name={survey.survey.name} date={date_created.format(mobile ? 'l' : 'll')} />,
        ];
    }

    function activity2DataFrame(surveys: SurveyResponse[], protocols: ProtocolResDataShort[]) {
        let survey_rows = surveys.map(s => survey2row(s))
        let protocol_rows = protocols.map(p => protocol2row(p))

        let rows = [...survey_rows, ...protocol_rows];
        rows.sort((a,b) => (a[0] as Moment).isBefore(b[0] as Moment ) ? 1: -1);
        let rows2 = rows.map(r => [r[1]] )
      return rows2;
    }


    let thereIsActivity = surveyResponses.length + protocolResponses.length > 0;

    return (
        <Container fluid className={'content-wrapper'}>
            <ParticipantHeader fetch_data/>
            <Row className={'bg-info p-2 d-flex justify-content-between'}>
            <Col className={'mt-2'}>
                <h5 className={'text-white  font-weight-bold mb-0 pb-0'}>
                    <FontAwesomeIcon icon={faFlask} id={'flaskIcon'}/>  {getContent("mind_lab__title")}
                </h5>
            </Col>
            <GoBackButton/>
            </Row>
            
            <Container fluid className={'px-3'}>
                <Row>
                    <Col sm={12}>
                        <Section
                            title={getContent("mind_lab__recent_activity__title")}
                            className={'secondary-section'}
                            headerClassName={'secondary-section-header'}
                            bodyClassName={'secondary-section-body'}
                        >
                            {thereIsActivity &&
                            <DataFrame
                                dataframe={activity2DataFrame(surveyResponses, protocolResponses)}
                                pageSize={5}
                                striped={true}
                                clickeableRow={true}
                            />}
                            {!thereIsActivity && <div className='bg-light text-info text-center p-3'>
                                {getContent("mind_lab__recent_activity__empty_data")}
                            </div>}
                        </Section>
                    </Col>
                </Row>
                <Row>
                <Col sm={12}>
                        <Section
                            title={getContent("evolution_chart__protocols__title")}
                            subtitle={getContent("evolution_chart__protocols__subtitle")}
                            className={'secondary-section'}
                            headerClassName={'secondary-section-header'}
                            bodyClassName={'secondary-section-body'}
                        >
                    <EvolutionChart />
                    </Section>
                </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default Participant;
