import $ from 'jquery';
import ServerConnection, {ResponseCallback, FailCallback, deepParseObjectTimestamps} from '../ServerConnection';
import { downloadFromURL } from '../../Commons/Utils/downloadFile';

import config from "../../Config";
import urls from "./urls.json";

// Import interfaces
import { Country, Examiner, Locale } from "../../Dashboard/interfaces";
import { FormSubmitData } from '../../Commons/Components/FormFields/types';
import { ServerError } from "../../store/server/types";
import { ProtocolData } from '../../Dashboard/Protocol/types';


//mocksups 
import mockup_examiner_info from "../../Demo/mockup_examiner_info.json";
import mockup_participants from "../../Demo/mockup_participants.json";
import mockup_surveys from "../../Demo/mockup_surveys.json";
import mocukp_protocols from "../../Demo/mockup_protocols.json";
import mockup_surveys_responses from "../../Demo/mockup_surveys_responses.json";
import mockup_one_survey_response from "../../Demo/mockup_one_survey_response.json";
import mockup_evolution from "../../Demo/mockup_evolution.json";
import mockup_protocolres from "../../Demo/mockup_protocolres.json";
import mockup_one_protocolres from "../../Demo/mockup_one_protocolres.json";
import mockup_one_protocolres_2 from "../../Demo/mockup_one_protocolres_2.json";
import mockup_locales from "../../Demo/mockup_locales.json";

const MSG_POST = "This is a demo version. You can't create data in this version. Please, contact us to get a full version of the app. https://tellapp.com.ar"

const Apirest = {

    conextion: new ServerConnection(config.apirest.baseURL),

    /** Server reports */

    server_report: (path: string, data: any, success: ResponseCallback = () => {} , fail: FailCallback = () => {}) => {
        $.post({
            url: config.apirest.baseURL + path,
            data: JSON.stringify(data),
            contentType: 'application/json',
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('jwt_token')},
            xhrFields: {withCredentials: true}
        })
            .then(Apirest.conextion.preprocessAndSuccess(path, 'POST', success))
            .fail(fail)
            .then(() => {console.log('Finished post server error report:', path)});
    },

    send_server_error_user_report: (serverError: ServerError, userComment: string, success: ResponseCallback, fail: ResponseCallback = () => {}) => Apirest.server_report(
        urls.reports.server_error_user_report,
        {
            error: serverError.error,
            url: serverError.url,
            method: serverError.method,
            data: JSON.stringify(serverError.data),
            user_comment: userComment
        },
        success,
        fail
    ),

    /*** Authentication */

    login: (email: string , password: string, success: ResponseCallback, fail: FailCallback) => success({access: "a", refresh: "b"}),

    check_logged_in: (success: ResponseCallback, fail: FailCallback) => success({jwt_tken: "a", jwt_refresh: "b"}),

    refresh_token: (success: ResponseCallback) => success({jwt_tken: "a", jwt_refresh: "b"}),

    send_reset_password_email(email: string, success: ResponseCallback) {
        Apirest.conextion.post(
            urls.authentication.password_reset.request,
            {email: email},
            success,
            success
        );
    },

    validate_reset_password_token: (token: string, success: ResponseCallback, fail: FailCallback) => Apirest.conextion.post(
        urls.authentication.password_reset.validate_token,
        {token: token},
        success,
        fail
    ),

    set_new_password: (token: string, password: string, success: ResponseCallback, fail: FailCallback) => Apirest.conextion.post(
        urls.authentication.password_reset.confirm,
        {token: token, password: password},
        success,
        fail
    ),

    /** Participant entries */

    update_participant: (patient_id: string, data: any, success: ResponseCallback, fail: FailCallback) => Apirest.conextion.patch(
        urls.user.participants.replace('{patient_id}', patient_id),
        data,
        success,
        fail
    ),

    get_participants: (success: ResponseCallback) => success(deepParseObjectTimestamps(mockup_participants)),


    create_participant: (data: any, success: ResponseCallback, fail: ResponseCallback = () => {}) =>  {
        alert(MSG_POST)     
    },

    /** Examiner entries */
    
    get_examiner_info: (success: (response: Examiner) => void) => success(deepParseObjectTimestamps(mockup_examiner_info)),

    get_examiners: (success: ResponseCallback) => Apirest.conextion.get(
        urls.user.examiners.replace('{examiner_id}/', ''),
        {},
        success
    ),

    create_examiner: (data: any, success: ResponseCallback, fail: ResponseCallback = () => {}) => Apirest.conextion.post(
        urls.user.examiners.replace('{examiner_id}/', ''),
        data,
        success,
        fail
    ),

    update_examiner: (doctor_id: string, data: any, success: ResponseCallback, fail: ResponseCallback = () => {}) => Apirest.conextion.patch(
        urls.user.examiners.replace('{examiner_id}', doctor_id),
        data,
        success,
        fail
    ),

    /*** Files */

    get_files: (participant_id: string, success: ResponseCallback = () => {}, fail: ResponseCallback = () => {}) => success(deepParseObjectTimestamps([])),

    upload_files: (participant_id: string, files: FileList, success: ResponseCallback = () => {}, fail: FailCallback = () => {}, callback: () => void = () => {}) => {
        
        let formDatas = Array.from(files).map(file => {
            let formData = new FormData();
            formData.append('file', file);
            return formData;
        });

        Apirest.conextion.post_files(
            urls.files.files.replace('{participant_id}', participant_id).replace('{file_id}/',''),
            formDatas,
            success, // Individual file upload success
            fail, // Individual file upload error
            callback // Final general callback after requests are finished
        );
    },

    delete_file: (participant_id: string, file_id: string, success: ResponseCallback = () => {}, fail: FailCallback = () => {}) => Apirest.conextion.delete(
        urls.files.files.replace('{participant_id}', participant_id).replace('{file_id}', file_id),
        success,
        fail
    ),

    download_file: (participant_id: string, file_id: string, fail: FailCallback = () => {}) => downloadFromURL("https://s3.amazonaws.com/audios.tellapp.org/speech_item_116__0958842715339787.webm?AWSAccessKeyId=ASIAZDL6NONNQ6WE24NS&Signature=94YZQ%2FJ5v2DcVbzX67NOMeRmxCU%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEIz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIBbttic6RdfL9jrURbRg6702fLwmmZ8qEZE7mnHuImnZAiEA7fqShxs2tFTUcY9mzqAwPapW5BvMDxotS3XK2pWxLngquwUIFBAAGgw2MjU3MTk3MzUxMzEiDMwXDHk9mxoNEiAnYCqYBSrewqOuckAiVry49Al6GemqO2LqT00ZnqUBMR9qkRXOT4N%2FR73F0PjMjvN5sLOdspKkzhlqoUOhRqTKVZ2yH7mtqritz4zGiyJrxPomkuCD5CDmDPkslOmJpbx6MxlhWrIMhPqbWGF9FreE%2BCn7nJd4e5yNBTB8NrMTumLSh8t4CMFhb%2FIlMH0eLnFWuseA4Z1SE6VZWt%2Brt5Hrb8R3SM3LiKkgSrbYPHx9L6G38XAF%2BSsXVmViK9W5GnoxFmKHRtsgyfTA7Z9Vb55FeQun1rD%2B62fAYubMrl2mI0EigqAt6rCrTkGBYEX2u1WMyGRBD%2FyV7ZyZJF5HpuwZ%2Bg5GgBfi9JOd2MqWxFDmBKuvXmTCBLOOtH0PTWzlyIaVLKFi0MMvmjcaKzFGa6DBcDnWNnUD0jBVxfcaYsCc0FHFi4w8pxarK9YH9WbHEqiBPo%2FWYyIC4js4DqHJ1EZeijayBj7ObGCaBwcRsO4Y5wttJnPusmYHWU3E2vlgck%2Fg%2BhDLIUWT63k2VbzuHWdWeEsxANJqnGjgJuiqAIpqR2tPvR%2FyEbjWSjJIA67E%2F1bKLRXT5fr0eaJYXI7BrDVD0Usua38St%2Fx0opn2aGepTJs6XBzX2ZgiTkvym3qLLZhesTvtNfTGhYJrhfTSv77u4JPt1fR5FbBCubDSu97xlYOw8Y1ABMjM7HfsmC3LZliWXkx2pKgFdKMURpT9uJ4ZNZZAF5id14Wgd%2BoHQIn7N0coD6150NC8yScaHKPw2gESZT2ADqJyCb9KK6%2B2CSX6oFdumhXetGRulzXZS9JySpxPDu1Imf3JBkMOUtp61nwbukGfXuCszDOyzb6SbuDNm1nRkXBYiHeKoc79sP7%2FbPzNEzjJUDutu%2FmDWIswzd%2FEpQY6sQE15V4ihL4a4FaolQAiDBlsOrflqMJCcYrMhzF%2BcUPTejuAknR5mr1ODSvWI1qJrtA0T%2B04REX8mIHlGeU08%2Fgx76BGny1iwKqrcmtPGK4E4TuW1sBQy3L6k1L45dVy9aTG4VwcSyzCsLgs2rLgBaPYLHGGxbZXa2PEZ2ImxdSEV%2BUo%2BqqD%2BW2LBp%2FMjnUSdbcU4LVHspslGX1NAUSEIM2e3keAnUnpOYeJaiKZV32SWak%3D&Expires=1689335995"),
    
    /*** Protocols */
    get_protocol_res_audio: (participant_id: string, protocolResID: string, protocol_item_id: string, success: ResponseCallback) => {
        // console.log("get_protocol_res_audio", participant_id, protocolResID, protocol_item_id)


          
        const dic_ids_audios:Record<string,Record<string,string>> = {
            "3838": {
                    "116": '1231377__tell_1_en__0__typical_day__DrkR.webm',
                    "117": '1231377__tell_1_en__1__pleasant_memory__RZhe.webm',
                    "118": '1231377__tell_1_en__2__picture_description_1__zW92.webm',
                    "119": '1231377__tell_1_en__3__picture_description_2__54Oi.webm',
                    "120": '1231377__tell_1_en__4__story_retelling__X72l.webm',
                    "121": '1231377__tell_1_en__5__paragraph_reading__WN7S.webm',
                    "122": '1231377__tell_1_en__6__sustained_vowel__21t0.webm',
                    "123": '1231377__tell_1_en__7__syllables__hdJY.webm',
                    "124": '1231377__tell_1_en__8__video_narration__wlkR.webm',
                    "125": '1231377__tell_1_en__9__car_without_wheels__w6Gp.webm',
                    "126": '1231377__tell_1_en__10__testimony__n6Xg.webm',
                    "127": '1231377__tell_1_en__11__silence__AwYD.webm'
            },
            "3843": {
                    "116": '1231379__tell_1_en__0__typical_day__KY5v.webm',
                    "117": '1231379__tell_1_en__1__pleasant_memory__TDZR.webm',
                    "118": '1231379__tell_1_en__2__picture_description_1__z4BP.webm',
                    "119": '1231379__tell_1_en__3__picture_description_2__IubG.webm',
                    "120": '1231379__tell_1_en__4__story_retelling__hC6f.webm',
                    "121": '1231379__tell_1_en__5__paragraph_reading__q07y.webm',
                    "122": '1231379__tell_1_en__6__sustained_vowel__23RC.webm',
                    "123": '1231379__tell_1_en__7__syllables__wCn9.webm',
                    "124": '1231379__tell_1_en__8__video_narration__5MP1.webm',
                    "125": '1231379__tell_1_en__9__car_without_wheels__gKM7.webm',
                    "126": '1231379__tell_1_en__10__testimony__7o0L.webm',
                    "127": '1231379__tell_1_en__11__silence__BVNy.webm'
            }
    }

    var audio_filename = dic_ids_audios[protocolResID][protocol_item_id]
        success("https://s3.amazonaws.com/publics.tellapp.org/"+audio_filename)
    },

    download_protocol_res_audio: (participant_id: string, protocolResID: string, protocol_item_id: string) => downloadFromURL("https://s3.amazonaws.com/audios.tellapp.org/speech_item_116__0958842715339787.webm?AWSAccessKeyId=ASIAZDL6NONNQ6WE24NS&Signature=94YZQ%2FJ5v2DcVbzX67NOMeRmxCU%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEIz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIBbttic6RdfL9jrURbRg6702fLwmmZ8qEZE7mnHuImnZAiEA7fqShxs2tFTUcY9mzqAwPapW5BvMDxotS3XK2pWxLngquwUIFBAAGgw2MjU3MTk3MzUxMzEiDMwXDHk9mxoNEiAnYCqYBSrewqOuckAiVry49Al6GemqO2LqT00ZnqUBMR9qkRXOT4N%2FR73F0PjMjvN5sLOdspKkzhlqoUOhRqTKVZ2yH7mtqritz4zGiyJrxPomkuCD5CDmDPkslOmJpbx6MxlhWrIMhPqbWGF9FreE%2BCn7nJd4e5yNBTB8NrMTumLSh8t4CMFhb%2FIlMH0eLnFWuseA4Z1SE6VZWt%2Brt5Hrb8R3SM3LiKkgSrbYPHx9L6G38XAF%2BSsXVmViK9W5GnoxFmKHRtsgyfTA7Z9Vb55FeQun1rD%2B62fAYubMrl2mI0EigqAt6rCrTkGBYEX2u1WMyGRBD%2FyV7ZyZJF5HpuwZ%2Bg5GgBfi9JOd2MqWxFDmBKuvXmTCBLOOtH0PTWzlyIaVLKFi0MMvmjcaKzFGa6DBcDnWNnUD0jBVxfcaYsCc0FHFi4w8pxarK9YH9WbHEqiBPo%2FWYyIC4js4DqHJ1EZeijayBj7ObGCaBwcRsO4Y5wttJnPusmYHWU3E2vlgck%2Fg%2BhDLIUWT63k2VbzuHWdWeEsxANJqnGjgJuiqAIpqR2tPvR%2FyEbjWSjJIA67E%2F1bKLRXT5fr0eaJYXI7BrDVD0Usua38St%2Fx0opn2aGepTJs6XBzX2ZgiTkvym3qLLZhesTvtNfTGhYJrhfTSv77u4JPt1fR5FbBCubDSu97xlYOw8Y1ABMjM7HfsmC3LZliWXkx2pKgFdKMURpT9uJ4ZNZZAF5id14Wgd%2BoHQIn7N0coD6150NC8yScaHKPw2gESZT2ADqJyCb9KK6%2B2CSX6oFdumhXetGRulzXZS9JySpxPDu1Imf3JBkMOUtp61nwbukGfXuCszDOyzb6SbuDNm1nRkXBYiHeKoc79sP7%2FbPzNEzjJUDutu%2FmDWIswzd%2FEpQY6sQE15V4ihL4a4FaolQAiDBlsOrflqMJCcYrMhzF%2BcUPTejuAknR5mr1ODSvWI1qJrtA0T%2B04REX8mIHlGeU08%2Fgx76BGny1iwKqrcmtPGK4E4TuW1sBQy3L6k1L45dVy9aTG4VwcSyzCsLgs2rLgBaPYLHGGxbZXa2PEZ2ImxdSEV%2BUo%2BqqD%2BW2LBp%2FMjnUSdbcU4LVHspslGX1NAUSEIM2e3keAnUnpOYeJaiKZV32SWak%3D&Expires=1689335995"),
    

    get_protocols: (success: ResponseCallback<ProtocolData[]>) => success(deepParseObjectTimestamps(mocukp_protocols)),
    
    create_protocol_res: (participant_id: string, protocol_id: string, success: ResponseCallback<{id: number}>) =>  {success({id: 1})},
    
    // Apirest.conextion.post(
    //     urls.protocols.responses.responses.replace('{participant_id}', participant_id).replace('{protocol_res_id}/', ''),
    //     {'protocol_id': protocol_id},
    //     success
    // ),

    send_protocol_item_response: (participant_id: string, protocolResID: string, protocol_item_id: string, speech: Blob, success: ResponseCallback, fail: FailCallback = () => {}) => {alert(MSG_POST); success({})},

    get_protocolres: (participant_id: string, protocolres_id: string, success: ResponseCallback) => {
        // console.log("get_protocolres", participant_id, protocolres_id)
        if (protocolres_id === "2") {
            return success(deepParseObjectTimestamps(mockup_one_protocolres_2))
        }else{
            return success(deepParseObjectTimestamps(mockup_one_protocolres))
        } 
    },

    get_participant_all_protocolres: (participant_id: string, success: ResponseCallback) => success(deepParseObjectTimestamps(mockup_protocolres)),

    get_participant_protocolres_evolution_data: (participant_id: string, success: ResponseCallback, fail: FailCallback) => success(deepParseObjectTimestamps(mockup_evolution)),

    /** Surveys */

    get_surveys: (success: ResponseCallback) => success(deepParseObjectTimestamps(mockup_surveys)),

    send_survey_response: (participant_id: string, survey_id: string, response: FormSubmitData, success: ResponseCallback, fail: FailCallback = () => {}) => success({}),

    get_participant_survey_responses: (participant_id: string, success: ResponseCallback, fail: FailCallback = () => {}) => success(deepParseObjectTimestamps(mockup_surveys_responses)),

    get_survey_response: (participant_id: string, survey_response_id: string, success: ResponseCallback, fail: FailCallback = () => {}) => success(deepParseObjectTimestamps(mockup_one_survey_response)),

    /** Locales */

    get_locales: (success: ResponseCallback<Locale[]>) => success(deepParseObjectTimestamps(mockup_locales)),

    get_countries: (success: ResponseCallback<Country[]>) => Apirest.conextion.get(
        urls.dashboard.countries,
        {},
        success
    ),

    /** Calendar */

    get_calendar_events: (success: ResponseCallback) => success(deepParseObjectTimestamps({protocolres:[],  surveys_res: []})),
};

export default Apirest;
