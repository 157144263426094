import * as React from 'react';
import { useSelector } from "react-redux";
import { useParams } from "react-router";


// Import interfaces
import {Participant} from "../../../../Dashboard/interfaces";

// Import store
import {StoreState} from "../../../../store";

// Import Components
import { Col, Container, Row, Nav } from "react-bootstrap";
import Menu from "./Menu";
import Survey from "../../../../Dashboard/Surveys/Form";
import ProtocolLink from "../../../../Dashboard/Protocol/protocolLink";

import "./ShortHeader.css";


interface HeaderStoreProps {
    patient?: Participant
}

interface HeaderProps {
    fetch_data?: boolean
    runTutorial?: () => void
}

const Header : React.FC<HeaderProps> = (props) => {

    let {runTutorial} = props;

    const mobiles = require('is-mobile');
    let mobile = mobiles();

    // This component uses the participant_id needed to get the patient from the store
    let {participant_id} = useParams<{participant_id: string}>();


    let {patient} = useSelector<StoreState, HeaderStoreProps>(state => {
        let pid = parseInt(participant_id);
        return {
            patient: state.participants.find(p => p.id === pid)
        };
    });

    let navItems =  navItemsTell

    function navItemsTell(): React.ReactNode[] {
        let items = [<Survey participant_id={participant_id} />];

        if (patient !== undefined) {
            items.push(<ProtocolLink participant_id={participant_id} />);
        }
        
        return items;
    };


    if (!patient) return null;

    
    return (
        <Container fluid className={' bg-white sticky-top '} >
            <Row className={'pb-0 border-bottom border-muted'}>
                <Col  xs={ mobile ? 12 : 5} className={'d-flex justify-content-end flex-column pl-0 ml-0 pb-2 mb-0'}>
                    <Nav  className={'no-border justify-content-start pl-0 mb-0 pb-0 patientMenu'}>
                        <Menu runTutorial={runTutorial} />
                    </Nav>

                </Col>
                <Col className={'d-flex justify-content-end  align-items-end pb-2 pt-2' + (mobile ? ' mobile-NavRow' : '')}>
                    <Row >
                        <Nav className={"justify-content-end patientHeaderButtons"}>
                            {navItems().map((item, idx) => <Nav.Item key={idx}>{item}</Nav.Item>)}
                        </Nav>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default Header;
