import { CLEAR_ALL } from "../actions_all";
import {
    CalendarActionTypes,
    CalendarStoreState,
    SET_CALENDAR_EVENTS,
    ADD_APPOINTMENT_EVENT,
    MODIFY_APPOINTMENT_EVENT,
    CLEAR_CALENDAR_EVENTS,
    DELETE_APPOINTMENT_EVENT
} from "./types";

const initialState: CalendarStoreState = {
    appointments: [],
    protocolres: []
};

export function CalendarReducer(state = initialState, action: CalendarActionTypes): CalendarStoreState{
    switch (action.type) {
        case SET_CALENDAR_EVENTS:
            return action.payload;

        case ADD_APPOINTMENT_EVENT:
            return {
                ...state,
                appointments: [...state.appointments, action.payload]
            };

        case DELETE_APPOINTMENT_EVENT:
            return {
                ...state,
                appointments: state.appointments.filter(p => p.id !== action.payload)
            };

        case MODIFY_APPOINTMENT_EVENT:
            let appointment = state.appointments.find(p => p.id === action.payload.id);

            if (appointment) {
                appointment.datetime_appointment = action.payload.datetime_appointment;
                let other_appointments = state.appointments.filter(p => p.id !== action.payload.id);
                return {
                    ...state,
                    appointments: [...other_appointments, appointment]
                }
            }

            return state;

        case CLEAR_ALL:
        case CLEAR_CALENDAR_EVENTS:
            return initialState;

        default:
            return state
    }
}