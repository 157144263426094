import * as React from 'react';
import {useState} from 'react';
import {useContent} from "../../Content/cms";
import { Stats } from "../../Commons/statistics";

// Import components
import Section, {SubSection} from "../../Commons/Section";
import DataFrame from '../../Commons/Components/DataFrame';
import { Modal, Alert, Spinner } from 'react-bootstrap';
import {WordCloudProps} from '../../Commons/Components/Charts/WordCloudChart';
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "../../Commons/MultipleComponents";
import { AreaChart, ResponsiveContainer, XAxis, YAxis, Area, ReferenceLine } from "recharts";

// Import CSS
import './feature_extraction.css';

/********************* data types **********************/

export interface SentimentGoogleData {
    score: number
    magnitude: number
}

interface CountingWordData {
    n_words: number
    word_letters__std: number
    word_letters__mean: number
    n_words_withoutstopwords: number
    word_letters_withoutstopwords__std: number
    word_letters_withoutstopwords__mean: number
}

export interface FeatureExtractionResData {
    id: number
    data: CountingWordData[] | WordCloudProps | SentimentGoogleData
    func: {
        id: number
        name: string
        label: string
        md5: string
    }
    transcript: {
        id: number
        text: string
        seconds: number
        transcription_task: number
    }
    feature_normalizer: {
        id: number
        data: any
        func_protocolitem_relationship: number
    }
}

/**************************************************/

export interface FeatureExtractionResScoresData{
    name: string
    status: "pending" | "failed" | "ready"
    value?: number
    histogram?: {x: number[], y: number[]}
}

/*************** Data renders *********************/

interface DataRenderProps {
    title: string
    subtitle?: string
    data: FeatureExtractionResScoresData[]
}

/****************************************************/

export const FeatureExtractionSummary : React.FC<DataRenderProps> = (props) => {

    let getContent = useContent();
    const mobiles = require('is-mobile');
    let mobile = mobiles();

    let [info, setInfo] = useState<string|undefined>();

    const header_content = mobile === false ? [
        getContent("protocol_result__feature_extraction__sumary_table__header__metric"),
        getContent("protocol_result__feature_extraction__sumary_table__header__current_value"),
        getContent("protocol_result__feature_extraction__sumary_table__header__population_distribution"),
        getContent("protocol_result__feature_extraction__sumary_table__header__description"),
        ''
    ] : [
        getContent("protocol_result__feature_extraction__sumary_table__header__metric"),
        getContent("protocol_result__feature_extraction__sumary_table__header__current_value"),
        ''
    ];

    const header = header_content.map(col => <p className={'font-weight-bold text-center'}>{col}</p>);

    let fullSerie = [];

    for (const value of Object.values(props.data)) {

        let value_str = null;
        let distribution = null;

        if (value.status === 'pending') {

            value_str = <div className={'text-center'}><Spinner animation={'border'} size={'sm'} /></div>;
            distribution = <div className={'text-center'}><Spinner animation={'border'} size={'sm'} /></div>;
        
        } else if (value.status === 'failed') {

            value_str = <Alert variant={'danger'}>{getContent('protocol_result__feature_extraction__error')}</Alert>;
            distribution = <Alert variant={'danger'}>{getContent('protocol_result__feature_extraction__error')}</Alert>;
        
        } else if (value.status === 'ready') {

            if (value.value !== undefined) {
                if (value.value >= 0.01){
                    value_str = <p className={'text-center'}>{ value.value.toFixed(2)}</p>;

                }else{
                    value_str = <p className={'text-center'}>{ value.value.toExponential(2)}</p>;

                }
            }

            if (value.histogram !== undefined) {

                let data = [];

                // Add an initial value with value zero to smooth the curve.
                // The value is added at the start of the valid range sent to not overpass the data x range
                data.push({
                    x: value.histogram.x[0],
                    y: 0
                });

                // Add the curve values using the center of the X bins as x points.
                for (let i = 0; i < value.histogram.x.length-1; i++) {
                    data.push({
                        x: Stats.mean(value.histogram.x.slice(i,i+2)),
                        y: value.histogram.y[i]!
                    });
                }

                // Add a last value  zero to smooth the curve.
                // The value is added at the end of the valid range sent to not overpass the data x range
                data.push({
                    x: value.histogram.x[value.histogram.x.length-1],
                    y: 0
                });

                distribution = (
                    <ResponsiveContainer width={'100%'} height={100}>
                        <AreaChart data={data}>
                            <XAxis
                                dataKey={'x'}
                                domain={['dataMin', 'dataMax']}
                                type={'number'}
                                tickFormatter={(x) => x.toFixed(2)}
                                interval={'preserveStartEnd'}
                                tick={{fontSize: 12}}
                                label={{
                                    value: '',
                                    position:'insideBottomRight',
                                    offset: 0
                                }}
                            />
                            <YAxis type={'number'} hide />
                            <Area
                                type={"monotone"}
                                dataKey={"y"}
                                stroke={"#5DBD33"}
                                fill={'#5dbd3361'}
                                strokeWidth={0.3}

                            />
                            <ReferenceLine
                                x={value.value}
                                stroke={'#ba1c1a'}
                                strokeWidth={1}
                            />
                        </AreaChart>
                    </ResponsiveContainer>
                );
            }
        }

        fullSerie.push(mobile === false ? [
            <p className={'mt-2'}>{getContent("protocol_result__feature_extraction__" + value.name + "__name")}</p>,
            value_str,
            distribution,
            <small className="d-inline-block ">{getContent("protocol_result__feature_extraction__" + value.name + "__short_explanation")}</small>,
            <IconButton
                text={getContent('protocol_result__feature_extraction__show_more_metric_info')}
                icon={{icon:faInfo, color:"#5CB6E7"}}
                onClick={() => setInfo(value.name)}
                className={'text-center w-100'}
            />
        ] : [
            <p className={'mt-2'}>{getContent("protocol_result__feature_extraction__" + value.name + "__name")}</p>,
            value_str,
            <IconButton
                text={getContent('protocol_result__feature_extraction__show_more_metric_info')}
                icon={{icon:faInfo, color:"#5CB6E7"}}
                onClick={() => setInfo(value.name)}
                className={'text-center w-100'}
            />
        ]);
    }

    return (
        <Section
            title={props.title}
            className={'secondary-section'}
            headerClassName={'secondary-section-header'}
            bodyClassName={'secondary-section-body'}
        >
            <SubSection title={''} className={'secondary-subsection '}>
                <DataFrame
                    header={header}
                    dataframe={fullSerie}
                    striped={false}
                    className={'header-bordered mt-3'}
                    columnClassName={'text-center'}
                    pageSize={fullSerie.length+1}
                    columnWidths={[2,3,2,4]}
                />
            </SubSection>
            <Modal show={info !== undefined} onHide={() => setInfo(undefined)} size="lg">
                <Modal.Header closeButton >
                    <Modal.Title>
                        {info !== undefined && getContent("protocol_result__feature_extraction__" + info + "__name")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><b>{getContent('protocol_result__measure_explanation__title__brief')}</b></p>
                    <p >{ info !== undefined && getContent("protocol_result__feature_extraction__" + info + "__short_explanation")} </p>
                    <p><b>{getContent('protocol_result__measure_explanation__title__long')}</b></p>
                    {info !== undefined && <div dangerouslySetInnerHTML={{__html: getContent("protocol_result__feature_extraction__" + info + "__large_explanation") as string}}/>}
                    <p><b>{getContent('protocol_result__measure_explanation__title__references')}</b></p>
                    {info !== undefined &&
                    <div dangerouslySetInnerHTML={{__html: getContent("protocol_result__feature_extraction__" + info + "__references") as string}}/>}
                </Modal.Body>
            </Modal>
        </Section>
    );
};
