import React, { useEffect, useState } from 'react';
import Apirest from '../../../Content/Apirest';
import { useContent } from '../../../Content/cms';
import { examiner2Values, getEmptyValue, PopUpError, PopUpSuccess } from "../../../Commons/Helpers";

// Import interfaces
import { Examiner, Locale } from "../../interfaces";

import {ProtocolData} from '../../Protocol/types';

import { 
    FormSubmitData,
    FormFieldValue,
    FormTelField,
    FieldScheme,
    ServerFormError,
    ServerFieldError,
    FormMultiselectField
} from '../../../Commons/Components/FormFields/types';
import { Survey } from "../../Surveys/types";

// Import the store
import { useDispatch, useSelector } from "react-redux";
import { ADD_OR_UPDATE_EXAMINER } from "../../../store/admin/types";
import { SET_EXAMINER } from "../../../store/examiner/types";
import { ExaminerStoreState } from '../../../store/examiner/types';
import { StoreState } from "../../../store";
import { ContentDict } from "../../../Content/cms";

// Import JSX Components
import { FormField } from "../../../Commons/Components/FormFields/Field";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { RowSpinner } from "../../../Commons/MultipleComponents";


export interface ServerExaminerFormData {
    locales: Locale[],
    protocols: ProtocolData[],
    surveys: Survey[]
}

interface ExaminerFormProps {
    examiner?: Examiner
    fields_schema: FieldScheme[]
}

const ExaminerForm : React.FC<ExaminerFormProps> = (props) => {

    let getContent = useContent();
    let content = useSelector<StoreState, ContentDict|undefined>(state => state.i18n.content);

    let [fields, setFields] = useState<FieldScheme[]>(props.fields_schema);
    let [serverErrors, setServerErrors] = useState<ServerFormError>({});
    let [formValues, setFormValues] = useState<FormSubmitData>({});

    // Server sync status state
    let [requestPending, setRequestPending] = useState<boolean>(false);

    let examiner = useSelector<StoreState, ExaminerStoreState>((state: StoreState) => state.examiner);

    let dispatch = useDispatch();
    let addOrUpdateExaminer = (response: Examiner) => dispatch({
        type: ADD_OR_UPDATE_EXAMINER,
        payload: response
    });
    let updateCurrentExaminer = (response: Examiner) => dispatch({
        type: SET_EXAMINER,
        payload: response
    });

    // Effect triggered only once after mounting.
    useEffect(
        () => {
            Apirest.get_protocols((response: ProtocolData[]) => {

                setFields(prev_fields => {

                    let new_fields = [...prev_fields];

                    let protocol_field = new_fields.find(f => f.name === 'protocols')!;
                    protocol_field.options = response.map(o => ({
                        value: o.id.toString(),
                        label: o.name
                    }));

                    return new_fields;
                });
            });

            Apirest.get_surveys((response: Survey[]) => {

                setFields(prev_fields => {

                    let new_fields = [...prev_fields];

                    let survey_field = new_fields.find(f => f.name === 'surveys')!;
                    survey_field.options = response.map(o => ({
                        value: o.id.toString(),
                        label: o.name
                    }));

                    return new_fields;
                });
            });

            Apirest.get_locales((response: Locale[]) => {

                setFields(prev_fields => {

                    let new_fields = [...prev_fields];

                    let locale_field = new_fields.find(f => f.name === 'locale')!;
                    locale_field.options = response.map(o => ({
                        value: o.id.toString(),
                        label: o.lang.lang + ' - ' + o.country
                    }));

                    return new_fields;
                });
            });
        },
        [content] // eslint-disable-line react-hooks/exhaustive-deps
    );

    useEffect(
        () => {
            setFormValues(examiner2Values(props.examiner));
        },
        [props.examiner] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const onChange = (name: string, value: FormFieldValue) => setFormValues(prev => {
        
        return ({
            ...prev,
            [name]: value
        });
    });

    const createExaminer = (data: FormSubmitData) => {
        Apirest.create_examiner(
            data,
            (response: Examiner) => {
                setRequestPending(false);
                setFormValues({});
                addOrUpdateExaminer(response);
                PopUpSuccess(<div className={'text-left ml-3 '}>
                    <p className={'mb-0'}>
                        {
                            (getContent('examiners_manager__form__server__create__success'))
                                .replace('{name}', response.first_name + ' ' + response.last_name)
                        }
                    </p>
                </div>)
            },
            (response) => {
                setRequestPending(false);
                for (const [key, value] of Object.entries(response.responseJSON as {[key: string]: ServerFieldError[]})) {
                    response.responseJSON[key] = value.map(
                        m => ({
                            message: getContent('examiners_manager__form__server__error__' + key + '__' + m.code),
                            code: m.code
                        })
                    );
                }
                setServerErrors(response.responseJSON);
                PopUpError(
                    <p>
                        {getContent('examiners_manager__form__server__create__error')}
                    </p>
                );
            }
        );
    };

    const updateExaminer = (data: FormSubmitData, examinerToUpdate: Examiner) => {
        // Note: This method makes the difference of updating a managed doctor
        // and updating the current doctor of the session.

        const onSuccess = (response: Examiner) => {
            setRequestPending(false);
            if (examiner !== null && examinerToUpdate.id === examiner.id) {
                updateCurrentExaminer(response);
            } else {
                addOrUpdateExaminer(response);   
            }

            PopUpSuccess(<div className={'text-left ml-3 '}>
                <p className={'mb-0'}>
                    {
                        (getContent('examiners_manager__form__server__update__success'))
                        .replace('{name}', response.first_name + ' ' + response.last_name)
                    }
                </p>
            </div>)
        };

        const onError = (response: any) => {
            setRequestPending(false);
            for (const [key, value] of Object.entries(response.responseJSON as {[key: string]: ServerFieldError[]})) {
                response.responseJSON[key] = value.map(
                    m => ({
                        message: getContent('examiners_manager__form__server__error__' + key + '__' + m.code) as string,
                        code: m.code
                    })
                );
            }
            setServerErrors(response.responseJSON);
            PopUpError(
                <p>
                    {getContent('examiners_manager__form__server__update__error')}
                </p>
            );
        };

        Apirest.update_examiner(
            examinerToUpdate.id.toString(),
            data,
            onSuccess,
            onError
        );
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        setRequestPending(true);
        setServerErrors({});

        // Format data to send
        let data : FormSubmitData = {};

        for (let field of fields) {
            data[field.name] = formValues[field.name];

            if (field.type === 'tel' && data[field.name] !== undefined) {
                let val = data[field.name] as FormTelField;
                if (val.number !== '') {
                    data[field.name] = val.country.dial_code + val.number;
                } else {
                    delete data[field.name]; // Remove phone without number
                }
            }

            if (field.type === 'multiselect' && data[field.name] !== undefined) {
                data[field.name] = (data[field.name] as FormMultiselectField).map(
                    o => parseInt(o)
                );
            }

            if (field.type === 'select' && data[field.name] !== undefined) {
                data[field.name] = parseInt(data[field.name] as string);
            }

            if (field.type === 'checkbox') {
                data[field.name] = data[field.name] || false;
            }
        }

        if (props.examiner === undefined) {
            createExaminer(data);
        } else {
            updateExaminer(data, props.examiner);
        }
    };

    let fetchingFormData = fields.filter(f => ['select','multiselect'].indexOf(f.type) > -1).some(
        f => f.options === undefined
    );

    if (fetchingFormData || examiner === null)  {
        return <RowSpinner className={'mt-3'} show/>;
    }

    return (
        <Form onSubmit={handleSubmit} className={'text-center mb-3 sticky-top'}>
            <Form.Group className={'text-left'}>
                <Container>
                    <Row className={'d-flex justify-content-center'}>
                        {fields.map(
                            (field, idx) =>
                                <Col key={idx} xs={10}>
                                    <FormField
                                        {...field}
                                        value={formValues[field.name] || getEmptyValue(field.type)}
                                        serverErrors={serverErrors[field.name]}
                                        placeholder={getContent('admin__form__' + field.name)}
                                        onChange={onChange}
                                    />
                                </Col>
                        )}
                    </Row>
                    <Row className={'d-flex justify-content-center'}>
                        <Button className={'m-3 col-9'} variant={'primary'} type={'submit'} disabled={requestPending} block>
                            {requestPending && <Spinner animation={'border'} size={'sm'} color={'#FFFFFF'} />}
                            {!requestPending && props.examiner === undefined && getContent('examiners_manager__form__submit__create')}
                            {!requestPending && props.examiner !== undefined && getContent('examiners_manager__form__submit__update')}
                        </Button>
                    </Row>
                </Container>
            </Form.Group>
        </Form>
    );
};

export default ExaminerForm;
