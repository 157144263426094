import * as React from 'react';
import Link from '../../../Commons/SiteMap/Link';
import { Moment } from 'moment';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '../../../Commons/MultipleComponents';
import { useContent } from '../../../Content/cms';
import { downloadProtocolRes } from '../../ProtocolRes';

interface ProtocolResLinkProps {
    id: number;
    date: Moment;
    participantId: string;
    protocolName: string;
    protocolCode: string;
}

const ProtocolLink: React.FC<ProtocolResLinkProps> = (props) => {
    let getContent = useContent();

    const mobiles = require('is-mobile');
    let mobile = mobiles();

    return (
        
        <Row>
            { !mobile && <Col>
                <Link
                    to={'protocolres'}
                    params={[props.participantId, props.id]}
                    className={'muted-link'}
                >
                    <p className={'mb-0'}>{props.date.format(mobile ? 'l' : 'll')}</p>
                </Link>
            </Col>}
            < Col>
                { mobile &&  <div className='d-flex justify-content-between'> 
                    <small className={'mb-0'}>{props.date.format(mobile ? 'l' : 'll')}</small>
                    <IconButton
                        onClick={() => downloadProtocolRes(getContent, props.participantId, props.id.toString())}
                        icon={{ icon: faDownload, color: "#5CB6E7" }}
                        text={getContent('protocol_result__feature__download_zip')}
                    /></div>
                }
                <Link
                    to={'protocolres'}
                    params={[props.participantId, props.id]}
                    className={'muted-link'}
                >
                      
                    <p className={'mb-0'}>{props.protocolName}</p>
                </Link>
                
            </Col>
            {!mobile &&
            <Col>
                <p className={'mb-0 text-center w-75'}>
                    <IconButton
                        onClick={() => downloadProtocolRes(getContent, props.participantId, props.id.toString())}
                        icon={{ icon: faDownload, color: "#5CB6E7" }}
                        text={getContent('protocol_result__feature__download_zip')}
                    />
                </p>
            </Col>}

        </Row>
    );
}

export default ProtocolLink;
