import * as React from "react";
import { useEffect, useState } from "react";
import IdleTimer from 'react-idle-timer';
import SessionManager from '../../Authentication/SessionManager';

import { StoreState } from "../../store";

// Import components
import LoaderSpinner from "../../LoaderSpinner";
import { useSelector } from "react-redux";

const ProtectedRoutes: React.FC = ({children}) => {

    let logged = useSelector<StoreState, boolean>(state => state.server.logged_in);

    let [checkingLogin, setCheckingLogin] = useState<boolean>(true);

    // Initially check if logged in the server
    useEffect(
        () => {
            SessionManager.checkLoggedIn(setCheckingLogin);
        },
        [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    if (logged) {
        return (
            <>
                <IdleTimer
                    timeout={3600000} // 1 hour
                    onIdle={SessionManager.logout}
                />
                {children}
            </>
        );
    }

    if (checkingLogin) {
        return <LoaderSpinner />;
    }

    return null;
};

export default ProtectedRoutes;