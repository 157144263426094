import * as React from 'react';
import {useParams} from "react-router";
import { useState, useEffect, useRef} from "react";
import Apirest from "../../../Content/Apirest";
import { useContent } from '../../../Content/cms';
import moment from 'moment';
import { buildRegexExpession, boldRegexMatch, PopUpError } from "../../Helpers";

// Import interface
import { Participant } from "../../../Dashboard/interfaces";
import { PatientFile } from "./types";

// Store state
import { StoreState } from "../../../store";
import { useSelector } from "react-redux";

// Import Components
import { Container, Modal, Row, Col, Form, Spinner, Alert, InputGroup, FormControl, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataFrame from "../DataFrame";
import {faFilter, faTimes, faFileUpload} from "@fortawesome/free-solid-svg-icons";
import "./index.css"

const fileComparator = (a: PatientFile, b: PatientFile) => {
    if (moment(a.date_created).isBefore(b.date_created)) {
        return 1;
    } else if (moment(a.date_created).isAfter(b.date_created)) {
        return 1;
    }

    return 0;
};

const FilesButton : React.FC = () => {

    let getContent = useContent();

    let file_placeholder = getContent("files__form__placeholder");
    let {participant_id} = useParams<{participant_id: string}>();

    let [showFilesModal, setShowFilesModal] = useState<boolean>(false);
    let [filename, setFilename] = useState<string[]>([file_placeholder]);
    //let [date, setDate] = useState<string>(new Date().toISOString().substring(0, 10)) => future optional date assign to file

    let [uploadingFiles, setUploadingFiles] = useState<string[]>([]); // Use name because not yet uploaded files are not PatientFiles with id
    let [dropping, setDropping] = useState<boolean>(false);

    let [patientFiles, setPatientFiles] = useState<PatientFile[]>([]);
    let [filteredPatientFiles, setFilteredPatientFiles] = useState<PatientFile[]>([]);
    let [filteringPatientFiles, setFilteringPatientFiles] = useState<boolean>(false);

    let inputFilterRef = useRef<any>();

    let patient = useSelector<StoreState, Participant | undefined>(state => {
		let pid = parseInt(participant_id);
        return state.participants.find(p => p.id === pid);
	});

    useEffect(
        () => {
            if (patient !== undefined) {
                Apirest.get_files(participant_id, setPatientFiles)
            }
        }, [patient] // eslint-disable-line react-hooks/exhaustive-deps
    );

    // Main trigger
    const handleOnClick = () => setShowFilesModal(true);

    const handleOnHide = () => {
        setFilename([file_placeholder]);
        setDropping(false);
        setShowFilesModal(false)
    };

    //submit Form
    const handleSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        let files: FileList = event.target.elements['file-input'].files;

        setUploadingFiles(Array.from(files).map(file => file.name));
        Apirest.upload_files(
            participant_id,
            files,
            (response: PatientFile) => {
                setPatientFiles(prev => [...prev, response].sort(fileComparator));
                setUploadingFiles(prev => prev.filter(f => f !== response.filename));
            },
            (qXHR: any, data: FormData) => {
                let file = data.get('file') as File;
                setUploadingFiles(prev => prev.filter(f => f !== file.name));
                console.log('ACAAA', );
                PopUpError(file.name + ': ' + getContent('files__upload__error__' + qXHR.responseJSON.error))
            }
        );

        // Reset form
        event.target.reset();
        setFilename([file_placeholder]);
    };

    //on select a file
    const handleFileSelected = ( files: FileList) => {

        if (files.length > 0) {
            setFilename(Array.from(files).map(f => f.name))
        } else {
            setFilename([file_placeholder]);
        }
    };

    const handleDeleteFile = (row: React.ReactNode[], index: number, rows_data: PatientFile[]) => {
        const file = rows_data[index];

        return Apirest.delete_file(
            participant_id,
            file.id.toString(),
            () => {

                console.log('delete server success');

                setFilteredPatientFiles(prev => prev.filter(f => f.id !== file.id));
                setPatientFiles(prev => prev.filter(f => f.id !== file.id));
            }
        );
    };

    const downloadFile = (file_id: string, filename: string) => {
        if (patient !== undefined) {
            Apirest.download_file(
                patient.id.toString(),
                file_id
            );
        }
    };

    const filterPatientFiles = (filter: string) => {

        if (filter === '') {
            setFilteredPatientFiles([]);
            setFilteringPatientFiles(false);
        } else {
            let regex = buildRegexExpession(filter.replace(' ',''));
            let newFilteredFiles = [];

            for (let file of patientFiles) {
                let [matches, formattedMatch] = boldRegexMatch(regex, file.filename);
                if (matches) {
                    newFilteredFiles.push({
                        ...file,
                        label: formattedMatch
                    });
                }
            }

            setFilteredPatientFiles(newFilteredFiles);
            setFilteringPatientFiles(true);
        }
    };

    const onClearFilter = () => {
        (inputFilterRef as any).current.value ='';
        setFilteredPatientFiles([]);
        setFilteringPatientFiles(false);
    };

    // Don't show until a valid patient is loaded from the store
    if (patient === undefined) return null;

    let filesHeader = [
        getContent("files__file_header__date"),
        getContent("files__file_header__file_name"),
        ''
    ];

    let filesToShow = filteringPatientFiles ? filteredPatientFiles : patientFiles;
    let files = filesToShow.map(file => [
        file.date_created.format('L'),
        <button
            onClick={() => downloadFile(file.id.toString(), file.filename)}
            className={'btn text-btn p-0 m-0'}
        >{file.label !== undefined ? file.label : file.filename}</button>
    ]);

    // Calculate max 3 file names for the label
    let file_labels = filename.slice(0,3).join(', ');
    if (filename.length > 3) {
        file_labels = file_labels + ', ...'
    }


    //dragging files
    const dragOverHandler = (ev : any) => {
        setDropping(true);
    }

    const dragLeaveHandler = (ev : any | undefined) => {
        setDropping(false);
    }

    const dropHandler = (ev:any | undefined) =>{
        setDropping(false);
    }

    const dropping_file = <div><p><b>{getContent('files__dragging_text')}</b></p></div>

    return (
        <Container>
            <button onClick={handleOnClick} className={'link-button'}>
                {getContent('files__menu_btn')}
            </button>

            {/* files modal */}
            <Modal size={files.length < 1 ? 'lg' : 'xl'}  show={showFilesModal} onHide={handleOnHide}>
                <Modal.Header closeButton>
                    <Modal.Title className={'text-primary'}>
                        {getContent('files__modal__title')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Row>
                {patientFiles.length > 0 &&
                    <Col sm={12} lg={8}>
                         <Row className={'d-flex justify-content-end '} sm={12} md={8}>
                            <Col xs={5} className={'position-absolute search-div pr-0'}>
                                <InputGroup className={"mb-2"}>
                                    <InputGroup.Prepend >
                                        <InputGroup.Text className={'bg-white'}>
                                            {filteringPatientFiles 
                                                ? <Spinner animation={'border'} size={'sm'}/>
                                                : <FontAwesomeIcon icon={faFilter}/>
                                            }
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                        id={"inlineFormInputGroup"}
                                        ref={inputFilterRef}
                                        placeholder={getContent("files__filter__placeholder")}
                                        onChange = {(event: any) => filterPatientFiles(event.target.value)}
                                    />
                                    <InputGroup.Prepend className={'filter-search-reset'}>
                                        <button
                                        onClick={onClearFilter}
                                        className={(filteredPatientFiles.length === 0 ? 'd-none' : null) + ' bg-transparent no-border'}
                                        > <FontAwesomeIcon icon={faTimes} color={ filteredPatientFiles.length === 0 ? '#fff' : '#495057'}/>
                                        </button>
                                    </InputGroup.Prepend>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>

                            <DataFrame
                                header={filesHeader}
                                className={'header-left header-bordered text-left'}
                                columnWidths={[2,7,1]}
                                dataframe={files}
                                rows_data={filesToShow}
                                pageSize={6}
                                onRemove={handleDeleteFile}
                                remove_tooltip_disabled_text={getContent('files__disabled_delete_tooltip_text')}
                            />
                        </Row>
                    </Col>}
                    <Col>
                    { files.length < 1 && <Alert variant={'warning'} className={'mt-1'}>{getContent("files__empty_alert")}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId={'formFileMultiple'} className={'mb-3'}>
                            <Row>
                                <Col>
                                <div id="drop_zone" className={'position-relative mb-4 pb-2'}>
                                <Form.File
                                    id={'file-input'}
                                    name={'file-input'}
                                    label={<div  id={'drag-zone-label'} className={ (filename[0] !== file_placeholder || dropping === true ? 'active ' : null) + ' mt-2'}><FontAwesomeIcon icon={faFileUpload} color={filename[0] !== file_placeholder || dropping === true ? '#5DBD33' : '#bdbdbd'} size={'lg'}/><p className={'pt-2'}>{dropping === true ? dropping_file : file_labels}</p></div>}
                                    type={'file'}
                                    className={'custom-file-label drop-area easy px-0' }
                                    onChange={ (e:any) => handleFileSelected(e.target.files) }
                                    onDragOver={ (e: any) => dragOverHandler(e)}
                                    onDragLeave={ (e: any) => dragLeaveHandler(e)}
                                    onDrop={(e: any) => dropHandler(e)}
                                    required
                                    multiple
                                    custom
                                />
                                </div>

                                    <div className={'d-flex justify-content-between py-3 w-100'}>

                                    <button
                                        type={"submit"}
                                        className={" btn btn-primary drop-area-submit w-100"}
                                    >
                                        {uploadingFiles.length > 0 && <Spinner animation={'border'} size={'sm'} />}
                                        {uploadingFiles.length === 0 && getContent("files__form__submit_button")}
                                    </button>
                                    </div>

                                </Col>
                            </Row>
                            <Row>
                                <ul style={{listStyleType: 'none'}}  className={'px-3'}>
                                    {uploadingFiles.map(
                                        (file, idx) => (
                                            <li key={idx}>
                                            <Button variant={'outline-primary'} className={'easy px-0 mt-2'}>
                                                <Spinner animation={'border'} size={'sm'} as={'span'} />
                                                {' '}
                                                {file}
                                            </Button>
                                            </li>
                                        )
                                    )}
                                </ul>
                            </Row>
                        </Form.Group>
                    </Form>
                    </Col>
                </Row>
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default FilesButton;
