import {AdminActionTypes, SET_MANAGED_EXAMINERS, ADD_OR_UPDATE_EXAMINER, CLEAR_ADMIN_STATE} from "./types";
import {Examiner} from "../../Dashboard/interfaces";
import { CLEAR_ALL } from "../actions_all";

const initialState: Examiner[] = [];

export function AdminReducer(state=initialState, action: AdminActionTypes): Examiner[] {
    switch (action.type) {
        case SET_MANAGED_EXAMINERS:
            return [...action.payload];

        case ADD_OR_UPDATE_EXAMINER:
            return [
                ...state.filter(p => p.id !== action.payload.id),
                action.payload
            ];

        case CLEAR_ALL:
        case CLEAR_ADMIN_STATE:
            return initialState;

        default:
            return state
    }
}