import Apirest from "../Content/Apirest";
import history from "../routerHistory";
import { store } from '../store';
import { SET_LOGGED_IN } from "../store/server/types";
import { CLEAR_ALL } from "../store/actions_all";
import SiteMap from "../Commons/SiteMap";

function minutes(minutes: number) {
    return minutes * 60 * 1000;
}

let keep_logged_in_interval: any = null;

const SessionManager = {

    setAuthenticationData: (data: {access: string, refresh?: string}) => {

        console.log('%c SET AUTHENTICATION DATA', 'color:#ffc42e');
        console.log('Data', data);

        localStorage.setItem('jwt_token', data.access);
        if (data.refresh !== undefined) {
            localStorage.setItem('jwt_refresh', data.refresh);
        }
    },

    clearAuthenticationData: () => {
        store.dispatch({type: CLEAR_ALL});
        SessionManager.stopKeepLoggedIn();
        localStorage.removeItem('jwt_token');
        localStorage.removeItem('jwt_refresh');
    },

    refreshToken: () => {
        console.log('%c REFRESH JWT TOKEN', 'color:#ffc42e');
        Apirest.refresh_token(
            response => SessionManager.setAuthenticationData(response)
        );
    },

    keepLoggedIn: () => {
        console.log('%c KEEP LOGGED IN', 'color:#ffc42e');
        SessionManager.stopKeepLoggedIn();
        SessionManager.refreshToken();

        keep_logged_in_interval = setInterval(() => {
            SessionManager.refreshToken();
        }, minutes(1));
    },

    stopKeepLoggedIn: () => {
        console.log('%c STOP KEEP LOGGED IN', 'color:#ffc42e');
        clearInterval(keep_logged_in_interval);
    },

    logout: () => {

        SessionManager.stopKeepLoggedIn();
        SessionManager.clearAuthenticationData();
        SessionManager.set_logged_in(false);

        history.push(SiteMap.getFullURL('auth'));
    },

    checkLoggedIn: (callback: (logged: boolean) => void) => {
        console.log('%c CHECK LOGGED IN', 'color:#ffc42e');

        if (localStorage.getItem('jwt_token') === null || localStorage.getItem('jwt_refresh') === null) {
            SessionManager.logout();
            callback(false);
            return;
        }

        Apirest.check_logged_in(
            () => {
                SessionManager.keepLoggedIn();
                SessionManager.set_logged_in(true);
                callback(false);
            },
            () => {
                SessionManager.logout();
                callback(false);
            }
        )
    },

    login: (email: string, password: string, successCallback: () => void, failCallback: () => void) => {

        // Before loging, clear store state to avoid trash values
        store.dispatch({type: CLEAR_ALL});

        Apirest.login(
            email,
            password,
            (response: {access: string, refresh: string}) => {
                
                console.log('Login success:');
                SessionManager.setAuthenticationData(response);
                SessionManager.keepLoggedIn();
                SessionManager.set_logged_in(true);

                history.push(SiteMap.getFullURL('dashboard'));
                successCallback();
            },
            (error) => {
                console.log('Login failed:', error);
                failCallback();
            }
        );
    },

    set_logged_in: (value: boolean) => {
        store.dispatch({
            type: SET_LOGGED_IN,
            payload: value
        });
    },

};

export default SessionManager;