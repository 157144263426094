import {Moment} from 'moment';
import { Protocol } from '../../Dashboard/interfaces';
import { AllActionsTypes } from '../actions_all';

export interface ServerAppointmentsData {
    id: number,
    datetime_appointment: Moment,
    datetime_created: Moment,
    patient: number
}

export interface ServerProtocolresData {
    id: number
    protocol: Protocol
    timestamp: Moment
    participant: number
}

// The global patients store state interface
export interface CalendarStoreState {
    appointments: ServerAppointmentsData[]
    protocolres: ServerProtocolresData[]
}

// The patients store action names
export const SET_CALENDAR_EVENTS = 'SET_CALENDAR_EVENTS';
export const ADD_APPOINTMENT_EVENT = 'ADD_APPOINTMENT_EVENT';
export const MODIFY_APPOINTMENT_EVENT = 'MODIFY_APPOINTMENT_EVENT';
export const DELETE_APPOINTMENT_EVENT = 'DELETE_APPOINTMENT_EVENT';
export const CLEAR_CALENDAR_EVENTS = 'CLEAR_CALENDAR_EVENTS';

// The patients store actions interfaces
interface setCalendarEventsActions {
    type: typeof SET_CALENDAR_EVENTS
    payload: CalendarStoreState
}

interface addAppointmentAction {
    type: typeof ADD_APPOINTMENT_EVENT
    payload: ServerAppointmentsData
}

interface modifyAppointmentAction {
    type: typeof MODIFY_APPOINTMENT_EVENT
    payload: {id: number, datetime_appointment: Moment}
}

interface deleteAppointmentAction {
    type: typeof DELETE_APPOINTMENT_EVENT
    payload: number
}

interface clearCalendarEventsAction {
    type: typeof CLEAR_CALENDAR_EVENTS
}

// Export the final patients store action states as a single type
export type CalendarActionTypes =
    | setCalendarEventsActions
    | addAppointmentAction
    | clearCalendarEventsAction
    | modifyAppointmentAction
    | deleteAppointmentAction
    | AllActionsTypes;