import React, {useEffect, useRef, useState} from "react";
import CountryCodes from '../../../../Data/CountryCodes.json';
import { getEmptyValue } from "../../../Helpers";

// Import interfaces
import {
    CountryCode,
    FieldScheme,
    FormFieldValue,
    FormSubmitData,
    FormTelField,
    ServerFieldError,
    FormMultiselectField
} from "../../../../Commons/Components/FormFields/types";

// Import CSS
import 'react-flags-select/css/react-flags-select.css';
import './index.css';

// Import components
import {Alert, Form, InputGroup} from "react-bootstrap";
import ReactFlagsSelect from 'react-flags-select';
import { useContent } from "../../../../Content/cms";

type OnChangeFunc = (name: string, value: FormFieldValue) => void;

type TelFieldProps = FieldScheme & {value?: FormTelField, onChange?: OnChangeFunc};

const TelField : React.FC<TelFieldProps> = (props) => {

    let {name, placeholder, required, disabled, type} = props;

    let emptyValue = getEmptyValue('tel') as FormTelField;
    let [currentValue, setCurrentValue] = useState<FormTelField>(props.value || emptyValue);

    let flagRef = useRef<ReactFlagsSelect>(null);

    useEffect(
        () => {
            let newValue = props.value || emptyValue;
            // Only update if new value differs from current value
            // (this is to avoid non sens updates if current value is already as the one to update)
            if (newValue.country.code !== currentValue.country.code || newValue.number !== currentValue.number) {
                setCurrentValue(newValue);
            }
        },
        [props.value] // eslint-disable-line react-hooks/exhaustive-deps
    );

    useEffect(
        () => {
            if (props.onChange!== undefined) {
                props.onChange(name, currentValue);
            }
            flagRef.current!.updateSelected(currentValue.country.code);
        },
        [currentValue] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const isANumber = (str: string) => !/\D/.test(str);

    const onChangeNumber = (event: any) => {
        if (isANumber(event.target.value)) {
            setCurrentValue(prev => ({
                country: prev.country,
                number: event.target.value
            }));
        }
    };

    const onChangeCode = (code: string) => {
        let newCountryCode: CountryCode = CountryCodes.find(c => c.code === code)!;
        setCurrentValue(prev => ({
            country: newCountryCode,
            number: prev.number
        }));
    };

    return (
        <>
            <InputGroup>
                <InputGroup.Prepend>
                    <ReactFlagsSelect
                        ref={flagRef}
                        countries={CountryCodes.map(c => c.code)}
                        defaultCountry={currentValue.country.dial_code}
                        searchable={true}
                        onSelect={onChangeCode}
                    />
                </InputGroup.Prepend>
                <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">{currentValue.country.dial_code}</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    required={required}
                    disabled={disabled}
                    onChange={onChangeNumber}
                    value={currentValue.number}
                />
            </InputGroup>
            <Form.Control
                type={'hidden'}
                name={name + '_dial_code'}
                value={currentValue.country.dial_code}
            />
        </>
    );
};

type MultiSelectFieldProps = FieldScheme & {value?: FormMultiselectField, onChange?: OnChangeFunc};

const MultiSelectField : React.FC<MultiSelectFieldProps> = (props) => {
    let {name, other_placeholder, required, options} = props;

    let emptyValue = getEmptyValue('multiselect') as string[];
    let [currentValues, setCurrentValues] = useState<FormMultiselectField>(props.value || emptyValue);
    let [currentOtherValue, setCurrenOthertValue] = useState<string>('');

    useEffect(
        () => {
            let newValue = props.value || emptyValue;
            /// Only update if new value differs from current value
            // (this is to avoid non sens updates if current value is already as the one to update)
            if (newValue.length !== currentValues.length || newValue.some(v => currentValues.indexOf(v) < 0)) {
                setCurrentValues(newValue);
            }
        },
        [props.value] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const onChange = (event: any) => {

        let selectedValue = event.target.name.split('_').pop();

        let values : FormMultiselectField = [];

        if (currentValues.indexOf(selectedValue) < 0) {
            values = [...currentValues, selectedValue];
        } else {
            values = currentValues.filter(v => v !== selectedValue);
        }

        setCurrentValues(values);

        if (props.onChange !== undefined) {
            props.onChange(name, values);
        }
    };

    const otherOnChange = (event: any) => {
        let newValue : string = event.target.value;
        setCurrenOthertValue(newValue);

        if (props.onChange !== undefined) {
            props.onChange(name + '_other', newValue);
        }
    };

    options = options || [];
    return <>
        {options.map(({value, label, disabled}, idx) => 
            <Form.Check
                key={idx}
                type={'checkbox'}
                name={name + '_' + value}
                required={required && currentValues.length === 0}
                disabled={disabled || props.disabled}
                label={label}
                checked={currentValues.indexOf(value as string) >= 0}
                onChange={onChange}
            />)
        }
        {currentValues.indexOf('other') >= 0 && <Form.Control
            className={"mt-2"}
            type={'text'}
            name={name + '_other'}
            required={true}
            placeholder={other_placeholder}
            value={currentOtherValue}
            onChange={otherOnChange}
        />}
    </>;
};

type SelectFieldProps = FieldScheme & {
    value?: string,
    onChange?: OnChangeFunc
};

const SelectField : React.FC<SelectFieldProps> = (props) => {

    let {name, required, disabled, options, placeholder, other_placeholder} = props;

    let emptyValue = getEmptyValue('select') as string;
    let [currentValue, setCurrentValue] = useState<string>(props.value || emptyValue);
    let [currentOtherValue, setCurrenOthertValue] = useState<string>('');

    useEffect(
        () => {
            let newValue = props.value || emptyValue;
            // Only update if new value differs from current value
            // (this is to avoid non sens updates if current value is already as the one to update)
            if (newValue !== currentValue) {
                setCurrentValue(newValue);
            }
        },
        [props.value] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const onChange = (event: any) => {
        let newValue : string = event.target.value;
        setCurrentValue(newValue);

        if (props.onChange !== undefined) {
            props.onChange(name, newValue);
        }
    };

    const otherOnChange = (event: any) => {
        let newValue : string = event.target.value;
        setCurrenOthertValue(newValue);

        if (props.onChange !== undefined) {
            props.onChange(name + '_other', newValue);
        }
    };

    return <>
        <Form.Control
            as={'select'}
            name={name}
            required={required}
            disabled={disabled}
            onChange={onChange}
            value={currentValue}
        >
            <option value={emptyValue} disabled>{placeholder}</option>
            {options && options.map(({value, label, disabled}, idx) =>
                <option
                    key={idx}
                    value={value}
                    disabled={disabled}
                >{label}</option>
            )}
        </Form.Control>
        {currentValue === 'other' && <Form.Control
            className={"mt-2"}
            type={'text'}
            name={name + '_other'}
            required={true}
            placeholder={other_placeholder}
            value={currentOtherValue}
            onChange={otherOnChange}
        />}

    </>;
};

type CheckboxFieldProps = FieldScheme & {value?: boolean, onChange?: OnChangeFunc};

const CheckboxField : React.FC<CheckboxFieldProps> = (props) => {

    let {name, disabled, label} = props;

    let emptyChecked = getEmptyValue('checkbox') as boolean;
    let [checked, setChecked] = useState<boolean>(props.value || emptyChecked);

    useEffect(
        () => {
            let newValue = props.value || emptyChecked;
            // Only update if new value differs from current value
            // (this is to avoid non sens updates if current value is already as the one to update)
            if (newValue !== checked) {
                setChecked(newValue);
            }
        },
        [props.value] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const onChange = (event: any) => {
        setChecked(event.target.checked);

        if (props.onChange !== undefined) {
            props.onChange(name, event.target.checked);
        }
    };

    return (
        <Form.Check
            type={'checkbox'}
            name={name}
            disabled={disabled}
            label={label}
            checked={checked}
            onChange={onChange}
        />
    );
};

type RadioButtonsFieldProps = FieldScheme & {value?: string, onChange?: OnChangeFunc};

const RadioButtonsField : React.FC<RadioButtonsFieldProps> = (props) => {
    let {name, required, disabled, options} = props;

    let emptyValue = getEmptyValue('radio') as string;
    let [currentValue, setCurrentValue] = useState<string>(props.value || emptyValue);

    useEffect(
        () => {
            let newValue = props.value || emptyValue;
            // Only update if new value differs from current value
            // (this is to avoid non sens updates if current value is already as the one to update)
            if (newValue !== currentValue) {
                setCurrentValue(newValue);
            }
        },
        [props.value] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const onChange = (event: any) => {
        let newValue : string = event.target.value;
        setCurrentValue(newValue);

        if (props.onChange !== undefined) {
            props.onChange(name, newValue);
        }
    };

    return <>
        {options && options.map(({value, label}, idx) => 
            <Form.Check
                key={name + idx.toString()}
                type={'radio'}
                name={name}
                required={required}
                disabled={disabled}
                value={value}
                label={label}
                checked={currentValue === value}
                onChange={onChange}
            />)
        }
    </>
};


function evaluateCondition(formValues: FormSubmitData, condition: string) {
    let condition_func = eval(condition); /* eslint no-eval: 0 */
    return condition_func(formValues);
}

interface FormFieldOwnProps {
    value?: FormFieldValue
    formValues?: FormSubmitData
    serverErrors?: ServerFieldError[]
    useCMS?: boolean
    onChange?: OnChangeFunc
}

type FormFieldProps = FieldScheme & FormFieldOwnProps

export const FormField: React.FC<FormFieldProps> = (props) => {

    let {type, name, placeholder, other_placeholder, label, required, disabled, options} = props;

    const getContent = useContent();

    if (required && label) {
        label += ' *';
    } else if (required && placeholder) {
        placeholder += ' *';
    }

    const defaultOnChange = (event: any) => {

        let value : FormFieldValue = event.target.value;

        if (props.onChange !== undefined) {
            props.onChange(name, value);
        }
    };

    // If there is a condition, then enable by condition
    if (props.condition !== undefined && props.formValues === undefined) {
        throw new Error('FormField: condition is defined but formValues is not');
    } else {
        let disabledByCondition = (
            props.formValues !== undefined &&
            props.condition !== undefined &&
            !evaluateCondition(props.formValues, props.condition)
        );
        
        if (disabledByCondition) {
            return null;
        }
    }

    let fieldComponent = null;

    switch (type) {
        case 'tel':
            fieldComponent = (
                <TelField
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    required={required}
                    disabled={disabled}
                    onChange={props.onChange}
                    options={options}
                    value={props.value as FormTelField}
                />
            );

            break;

        case 'multiselect':
            fieldComponent = (
                <MultiSelectField
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    other_placeholder={other_placeholder}
                    required={required}
                    disabled={disabled}
                    onChange={props.onChange}
                    options={options}
                    value={props.value as string[]}
                />
            );
            break;

        case 'select':
            fieldComponent = (
                <SelectField
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    other_placeholder={other_placeholder}
                    required={required}
                    disabled={disabled}
                    onChange={props.onChange}
                    options={options}
                    value={props.value as string | undefined}
                />
            );
            break;

        case 'checkbox':
            fieldComponent = (
                <CheckboxField
                    type={type}
                    name={name}
                    disabled={disabled}
                    label={props.label}
                    value={props.value as boolean}
                    onChange={props.onChange}
                />
            );
            break;

        case 'radio':
            fieldComponent = (
                <RadioButtonsField
                    type={type}
                    name={name}
                    required={required}
                    disabled={disabled}
                    options={options}
                    value={props.value as string}
                    onChange={props.onChange}
                />
            );
            break;

        default:
            fieldComponent = (
                <Form.Control
                    type={type}
                    name={name}
                    required={required}
                    placeholder={placeholder}
                    disabled={disabled}
                    value={props.value as string}
                    onChange={defaultOnChange}
                />
            );
    }

    return (
        <div className="mt-2">
            {label && props.type !== 'checkbox' && <Form.Label className={"ml-2 label"}>{label}</Form.Label>}
            {fieldComponent}
            {props.serverErrors && props.serverErrors.map(
                (message, idx) =>
                    <Alert key={idx} className={"mt-2"} variant={'danger'}>
                        {!props.useCMS && message}
                        {props.useCMS && getContent('forms__errors__' + type + '__' + message)}
                    </Alert>
            )}
        </div>
    );
};
