import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useState } from 'react';
import Apirest from "../../Content/Apirest";
import SiteMap from "../../Commons/SiteMap";
import { useLocalContent } from "../GetLanguageAuth/useLocalContent";


import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import { PopUpError, PopUpSuccess } from "../../Commons/Helpers";
import AuthLanguageForm from "../GetLanguageAuth"


const ResetPassword: React.FC = () => {
    
    let history = useHistory();
    let token = useParams<{token: string}>().token;
    let welcome = useParams<{is_welcome: string}>().is_welcome;
    console.log('ISwolcome', welcome)
    let getContent = useLocalContent();

    let [formValues, setFormValues] = useState({password1:'', password2:''});
    let [sendingNewPassword, setSendingNewPassword] = useState<boolean>(false);
    let [samePassword, setSamePassword] = useState<boolean>(true);
    let [validToken, setValidToken] = useState<boolean>(false);
    let [checkingValidToken, setCheckingValidToken] = useState<boolean>(true);
    let [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        Apirest.validate_reset_password_token(
            token,
            () => {
                setValidToken(true);
                setCheckingValidToken(false);
            },
            () => {
                PopUpError('Token inválido');
                setValidToken(false);
                setCheckingValidToken(false);
            }
        );
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSamePassword(formValues.password1 === formValues.password2);
    }, [formValues]);

    const handleInput = (event: any) => {
        const {name, value} = event.target;
        setFormValues({...formValues, [name]: value});
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setSendingNewPassword(true);
        setErrors([]);

        Apirest.set_new_password(
            token,
            formValues.password1,
            () => {
                PopUpSuccess(getContent('authentication__set_password_success'));
                setSendingNewPassword(false);
                history.replace(SiteMap.getFullURL('auth'));       
            },
            (response) => {

                setErrors(response.responseJSON.password.map(
                    (error: string) => getContent('set_password__error__' + error.toLowerCase().replaceAll(' ', '_'))
                ));
                
                setSendingNewPassword(false);
            }
        )
    };

    let title_text = welcome === 'false' ? 
        (                           
            <Card.Title className={'m-auto'}>
                <p><small>{getContent('authentication__set_password_instruction')}</small></p>
            </Card.Title> 
        ) :
        ( 
            <Card.Title className={'m-auto'}>
                <p><small>{getContent('authentication__set_password_welcome_instruction')}</small></p>
            </Card.Title>
        );


    if (checkingValidToken) {
        return (
            <Spinner animation="border" variant="primary" />
        )
    }

    if (!validToken) {
        return null;
    }

    return (
        <Container fluid className={'deg-bg vh-100'}>
            <Row className={'d-flex justify-content-center text-center d-flex align-items-center pt-5'}>
                <Col sm={6}>
                    <Card className={'bg-light w-75 m-auto'}>
                        <Card.Body>
                        <img 
                                src="/logo_tell_header.svg"
                                alt={'logo'}
                                className={'align-self-center p-3 mb-2'}
                            />
                                {title_text}
                                <Card.Text>
                                <Form className={'text-left'} onSubmit={handleSubmit}>
                                    <Form.Group >
                                        <Form.Control
                                            as={'input'}
                                            type={'password'}
                                            name={'password1'}
                                            value={formValues.password1}
                                            onChange={handleInput}
                                            placeholder={getContent("authentication__set_password_placeholder_password")}
                                            required
                                        />
                                    </Form.Group>

                                    <Form.Group >
                                        <Form.Control
                                            as={'input'}
                                            type={'password'}
                                            name={'password2'}
                                            value={formValues.password2}
                                            onChange={handleInput}
                                            placeholder={getContent("authentication__set_password_placeholder_password")}
                                            required
                                        />
                                    </Form.Group>

                                    {!samePassword && <Alert  variant={'danger'}>
                                        {getContent('authentication__set_password_passwords_match_error')}
                                    </Alert>}

                                    {errors.length > 0 && <Container className={'mt-3'}>
                                        <Row>
                                            <Col>
                                                <Alert variant={'danger'} className="text-left">
                                                    <ul>
                                                        {errors.map((error: string) => <li>{error}</li>)}
                                                    </ul>
                                                </Alert>
                                            </Col>
                                        </Row>
                                    </Container>}

                                    <Container className={'mt-3'}>
                                        <Row>
                                            <Col>
                                                <Alert variant={'info'} className="text-left">
                                                    <ul>
                                                        <li>{getContent('set_password__constraints__more_than_8_characters')}</li>
                                                        <li>{getContent('set_password__constraints__lower_upper_case')}</li>
                                                        <li>{getContent('set_password__constraints__spetial_character')}</li>
                                                        <li>{getContent('set_password__constraints__alpha_numeric_character')}</li>
                                                    </ul>
                                                </Alert>
                                            </Col>
                                        </Row>
                                    </Container>

                                    <Button type={'submit'} disabled={sendingNewPassword} className={'w-100'}>
                                        {!sendingNewPassword && <p className={'p-0 mb-0'}>{getContent('authentication__set_password_button')}</p>}
                                        {sendingNewPassword && <Spinner animation="border" variant="primary" />}
                                    </Button>
                                </Form>

                                
                                </Card.Text>
                        </Card.Body>
                    </Card>
                    <AuthLanguageForm />
                </Col>
            </Row>
    </Container>
    );
};

export default ResetPassword;