import React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useContent } from '../../Content/cms';
import admin_fields_schema from './ExaminerForm/admin_form_schema.json';

import { Examiner } from '../interfaces';
import { FieldScheme } from '../../Commons/Components/FormFields/types';

// Import JSX Components
import { Container, Row, Col, Button } from 'react-bootstrap';
import ExaminerForm from './ExaminerForm';
import ExaminersList from "./ExaminersList";
import ExaminerHeader from "../../Commons/Components/Examiner/Header";

// Import the store
import { StoreState } from "../../store";
import { ExaminerStoreState } from "../../store/examiner/types";
import { useSelector } from "react-redux";
import SiteMap from "../../Commons/SiteMap";
import { RowSpinner } from "../../Commons/MultipleComponents";


const Home: React.FC = () => {

    let history = useHistory();

    let getContent = useContent();

    let [examinerToEdit, setExaminerToEdit] = useState<Examiner|undefined>(undefined);

    let examiner = useSelector<StoreState, ExaminerStoreState>((state: StoreState) => state.examiner);

    if (examiner !== null && !examiner.is_manager) {
        // Here I use a replace instead of push to avoid the user to comback to the home page from the browser buttons
        history.replace(SiteMap.getFullURL('dashboard'));
        return null;
    }

    return (
        <Container  fluid >
            <Container className={'patients fluid d-flex-column bg-white'} fluid>
                <ExaminerHeader/>
            </Container>
            <Container fluid>
                {examiner === null && <RowSpinner show />}
                {examiner !== null && examiner.is_manager &&
                    <Row>
                        <Col xs={12} md={4}>
                            <Container fluid>
                                <Row>
                                    <Col>
                                        <ExaminersList onEditExaminer={setExaminerToEdit}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} className={'text-left'}>
                                        <Button block onClick={() => setExaminerToEdit(undefined)}>
                                            {getContent('examiners_manager__examiners_list__add_new')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col xs={12} md={8} className={'bg-light'}>
                            <Container className={'mt-3'}>
                                <Row>
                                    <Col className={'text-center'}>
                                        <h5 className={'p-2 text-primary '}>
                                            {examinerToEdit === undefined && getContent('examiners_manager__form__create_examiner_title')}
                                            {examinerToEdit !== undefined && getContent('examiners_manager__form__edit_examiner_title')}
                                        </h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <ExaminerForm
                                            examiner={examinerToEdit}
                                            fields_schema={admin_fields_schema as FieldScheme[]}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                }
            </Container>
        </Container>
    );
};

export default Home;
