import React from 'react';
import {useState} from "react";

import SessionManager from '../../Authentication/SessionManager';
import { useContent } from '../../Content/cms';
import { useHistory } from 'react-router-dom';
import SiteMap from "../../Commons/SiteMap";

// Import de Store
import { useSelector } from "react-redux";
import {StoreState} from "../../store";

// Import the interfaces
import { Examiner, Participant } from '../interfaces';

// Import css styles
import './header.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';

// Import JSX components
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { Navbar, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "../../Commons/SiteMap/Link";
import PatientSearch from "../DataLoader/PatientSearch";
import Calendar from "../Calendar";


const Header: React.FC = () => {

    let getContent = useContent();

    let history = useHistory();
    
    const mobiles = require('is-mobile');
    let mobile = mobiles();

    let examiner = useSelector<StoreState, Examiner>(state => state.examiner!);

    let [onSelectRedirectSite] =  ['participant']

    let [showAppointmentModal, setShowAppointmentModal] = useState<boolean>(false);

    let logo = mobile ? '/onlybrain_Logo.svg' : '/logo_tell_header.svg';

    const logout = () => {
        SessionManager.clearAuthenticationData();
        history.push(SiteMap.getFullURL('auth'));
    };


    const handleOnSelect = (selection: Participant[]) => {
        history.push(
            SiteMap.getFullURL(onSelectRedirectSite, [selection[0].id])
        );
    };
    

    return (
        <Navbar id={'main-header'} as={'header'} expand={'sm'} variant={'light'} bg={'white'}  fixed={'top'} className={mobile ? 'mobile-header p-3' : 'p-3'}>
            <div className={'w-25'}>
            <Link to={'dashboard'} >
                <Navbar.Brand>
                    <img className={mobile ? 'mobile-logo mr-2' : 'desktop-logo mr-2'} alt={"Logo"} src={logo} />
                </Navbar.Brand>
            </Link>
            </div>
            <div className={'w-75 d-flex justify-content-end'} id={'doctor-menu-dropdown'}>
                <div className={'d-none d-md-block '}>
                    <PatientSearch
                        onSelect={handleOnSelect}
                        placeholder={getContent("base__header__search_participant__placeholder") as string}
                        clearOnSelect
                    />
                </div>
            
                <NavDropdown
                    title={<>{examiner.first_name} {examiner.last_name} <FontAwesomeIcon icon={faUserCircle} /></>}
                    id={"nav-dropdown"}
                    className={'ml-3'}
                >
                    <NavDropdown.Item as={'div'}>
                        <button
                            className={'ml-0 mt-1 px-3 text-btn text-left bg-transparent no-border'}
                            onClick={() => setShowAppointmentModal(true)}
                        > {getContent("base__header__examiner_menu__agenda")}
                        </button>
                    </NavDropdown.Item>

                    <NavDropdown.Divider  />

                    {examiner.is_manager && <>
                        <NavDropdown.Item as={'div'}>
                            <Link to={'admin'} params={[null]} className={'secondary-link'}>
                                {getContent("base__header__examiner_menu__manage_examiners")}
                            </Link>
                        </NavDropdown.Item>
                        < NavDropdown.Divider />
                    </>}


                    <NavDropdown.Item onClick={logout}>
                        {getContent("base__header__examiner_menu__logout")}
                    </NavDropdown.Item>



                </NavDropdown>
                </div>
            
            <Calendar
                            show={showAppointmentModal}
                            onHide={() => setShowAppointmentModal(false)}
                            
                        />
        </Navbar>
    );
};

export default Header
