import React from "react";

// Import components
import {Container, Row, Col} from "react-bootstrap";

// Import css
import './index.css'

const NotFound: React.FC = () => (
    <Container fluid className={'main-404'}>
    <Row className={'align-items-center m-auto '}>
        <Col xs={5} className={'d-flex center-error justify-content-end'}>
            <img src="/Iso_logo.png" alt="loading" className={'mb-5'}/>
            <div className={'pulse align-self-end'}></div>
        </Col>
        <Col xs={6} className={'center-error'}>
            <h3 className={'align-self-center style-404 mt-3'}>404</h3>
            <h4 className={'font-weight-bold'}>Disculpas!</h4>
            <h5>Pagina no encontrada/Page Not Found</h5>
        </Col>

    </Row>
    </Container>
);

export default NotFound;
