import React, {FC, useState, useEffect} from 'react';
import { useContent } from '../../../Content/cms';
import Recorder, { RecorderProps } from '../Recorder';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import {ProtocolData, Item} from '../types';

interface ProtocolItemsOwnProps {
    protocol: ProtocolData;
    onProtocolStart?: (protocol: ProtocolData) => void;
    onProtocolEnd?: (blobs: Blob[], protocol: ProtocolData) => void;
    onRecordingStart?: (item: Item) => void;
    onRecordingStop?: (blob: Blob, item: Item) => void;
    onFail?: (error: Error) => void;
}

export type ProtocolItemsProps = 
    & Omit<RecorderProps, 'onRecordingStart' | 'onRecordingStop'>
    & ProtocolItemsOwnProps;

const ProtocolItems:FC<ProtocolItemsProps> = (props) => {

    let getContent = useContent();

    // declare default values for callbacks
    const onProtocolStart = props.onProtocolStart || (() => {});
    const onProtocolEnd = props.onProtocolEnd || (() => {});
    const onRecordingStart = props.onRecordingStart || (() => {});
    const onRecordingStop = props.onRecordingStop || (() => {});

    // Store the current item index
    let [currentItemIdx, setCurrentItemIdx] = useState<number>(0);

    // disable/enable the recorder
    let [recorderDisabled, setRecorderDisabled] = useState<boolean>(true);

    let [mediaDisabled, setMediaDisabled] = useState<boolean>(false);

    // Store recorded blobs
    let [recordedBlobs, setRecordedBlobs] = useState<Blob[]>([]);

    // Store mic permission state
    let [micPermission, setMicPermission] = useState<boolean>(false);

    // call onProtocolStart callback when protocol is loaded
    useEffect(
        () => {
            onProtocolStart(props.protocol);
        }, [props.protocol] // eslint-disable-line react-hooks/exhaustive-deps
    );

    useEffect(
        () => {
            // Update the item recording state if there is item
            if (currentItemIdx < props.protocol.items.length - 1) {
                let item = props.protocol.items[currentItemIdx];
                let hasAudioOrVideo = (
                    (item.audio !== undefined) ||
                    (item.video !== undefined)
                )
                setRecorderDisabled(hasAudioOrVideo);

                // Allow media to be played again
                setMediaDisabled(false);
            }
        }, [currentItemIdx] // eslint-disable-line react-hooks/exhaustive-deps
    )

    // The stop recording callback must call the parent function.
    function handleOnRecordingStop(blob: Blob) {

        // Add the blob to the list of recorded blobs
        setRecordedBlobs(prevBlobs => [...prevBlobs, blob]);

        // Call the parent function
        onRecordingStop(blob, currentItem);

        // Update the current item index
        setCurrentItemIdx(currentItemIdx + 1);

        // If it's the last item, call the parent callback
        if (currentItemIdx === props.protocol.items.length - 1) {
            onProtocolEnd([...recordedBlobs, blob], props.protocol);
            return;
        }
    }

    // Define function to explicitelly ask for mic permission
    let onFail = props.onFail || (() => {});
    function handleMicPermission() {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(function() {
                setMicPermission(true)
            })
            .catch(function(err) {
                onFail(err);
            });
        ;
    }

    function onMediaEnd() {
        setRecorderDisabled(false);
        setMediaDisabled(true);
    }

    if (micPermission === false) {
        return (
            <Container className={'protocol'} fluid>
                <h4 className='protocol-header'>
                    {getContent('protocol__mic_permission__title')}
                </h4>
                <Row className={'task-body'}>
                <Col>
                    {getContent('protocol__mic_permission__text')}
                    <br/><br/>
                    <h4 className='protocol-header'>{getContent('protocol__mic_permission__instructions_title')}</h4>
                    <br/>
                    <p dangerouslySetInnerHTML={{__html: getContent('protocol__mic_permission__instructions_text') as string}}></p>
                    </Col>
                </Row>
                <Row className={'task-body'}>
                    <Col>
                        <Button className='recorder-button' onClick={handleMicPermission} block>
                            {getContent('protocol__mic_permission__btn')}
                        </Button>
                    </Col>
                </Row>
            </Container>
        );
    }

    if (currentItemIdx >= props.protocol.items.length) {
        return null;
    }

    let currentItem = props.protocol.items[currentItemIdx]!;
    
    let completed = Math.floor(((currentItemIdx+1) / props.protocol.items.length) * 100);
    
    console.log('mediaDisabled', mediaDisabled);

    return (
        <Container className={'protocol'} fluid>
            <h3 className='protocol-header'>
                {props.protocol.name}
            </h3>
            <Row >
                <Col sm={1} />
                <Col sm={10}>
                    <div className="progress">
                        <div 
                            className={"progress-bar"}
                            role={"progressbar"}
                            style={{width: completed.toString() + '%'}}
                            aria-valuenow={completed}
                            aria-valuemin={0}
                            aria-valuemax={100}
                        >({currentItemIdx+1}/{props.protocol.items.length})</div>
                    </div>
                </Col>
                <Col sm={1} />
            </Row>
            <Row className='task-body'>
                <Col>
                    <Container fluid>

                    {/** Show title if there is title */}
                    {currentItem.title && <Row>
                        <Col>
                            <h5 className='protocol-item-title'>{currentItem.title}</h5>
                        </Col>
                    </Row>}

                    {/** Show the trigger as parsed HTML*/}
                    <Row>
                        <Col>
                            <div
                                className={'text-left'}
                                dangerouslySetInnerHTML={{'__html': currentItem.trigger}}
                            />
                        </Col>
                    </Row>

                    {/** Show the image if there is image*/}
                    {currentItem.image && 
                        <Row className='mt-3 protocol-item-stimuly'>
                            <Col>
                                <img className={'img-fluid'} alt='protocol item' src={currentItem.image} />
                            </Col>
                        </Row>
                    }

                    {/** Show the audio if there is audio*/}
                    {currentItem.audio && 
                        <Row className='mt-3 protocol-item-stimuly'>
                            <Col>
                                <audio
                                    title={currentItem.trigger}
                                    onEnded={onMediaEnd} 
                                    src={mediaDisabled? '#' : currentItem.audio}
                                    controls 
                                />
                            </Col>
                        </Row>
                    }

                    {/** Show the video if there is video*/}
                    {currentItem.video &&
                        <Row className='mt-3 protocol-item-stimuly'>
                            <Col>
                                <video
                                    className={'w-100 h-auto'}
                                    onEnded={onMediaEnd}
                                    src={mediaDisabled? '#' : currentItem.video}
                                    controls
                                />
                            </Col>
                        </Row>
                    }
                    </Container>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Recorder
                        onRecordingStart={() => onRecordingStart(currentItem)}
                        onRecordingStop={handleOnRecordingStop}
                        sendToSigmind={props.sendToSigmind}
                        disabled={recorderDisabled}
                        minDuration={currentItem.min_duration}
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default ProtocolItems;