import * as React from "react";
import TreeModel, {Node} from "tree-model";
import {ReactText} from "react";

// Import components
import { Route, RouteComponentProps } from "react-router-dom";

// Import views components
import Home from "../../Dashboard/Home";
import Calendar from "../../Dashboard/Calendar";
import EditParticipant from "../../Dashboard/EditParticipant";
import Participant from "../../Dashboard/Participant";
import ProtocolRes from "../../Dashboard/ProtocolRes";
import Admin from "../../Dashboard/Admin";
import Protocol from "../../Dashboard/Protocol";
import Login from "../../Authentication/Login";
import ResetPassword from "../../Authentication/ResetPassword";
import SetPassword from "../../Authentication/SetPassword";

export interface viewInfo {
    id: string,
    path: string
    name: string
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
    params: string[]
    isPublic?: boolean
}

export type Params = (string|number|ReactText|null)[];

const authSiteViewsTree = new TreeModel().parse<viewInfo>({
    id: 'auth',
    name: "commons__sitemap__login",
    path: '',
    params: [],
    component: Login,
    children:[
        {
            id: 'reset_password',
            name: "commons__sitemap__reset_password",
            path: '/reset_password',
            params: [],
            component: ResetPassword
        },
        {
            id: 'set_password',
            name: "commons__sitemap__set_password",
            path: '/set_password',
            params: ['token', 'is_welcome'],
            component: SetPassword
        }
    ]
});

const dashboardSiteViewsTree = new TreeModel().parse<viewInfo>({
    id: 'dashboard',
    name: "commons__sitemap__dashboard",
    path: '/dashboard',
    params: [],
    component: Home,
    children: [
        {
            id: 'calendar',
            name: 'commons__sitemap__calendar',
            path: '/dashboard/agenda',
            params: [],
            component: Calendar,
        },
        {
            id: 'admin',
            name: 'commons__sitemap__admin',
            path: '/dashboard/admin',
            params: ['examiners_id?'],
            component: Admin,
        },
        {
            id: 'participant',
            name: 'commons__sitemap__participant',
            path: '/dashboard/participant',
            params: ['participant_id'],
            component: Participant,
            children: [
                {
                    id: 'protocolres',
                    name: 'commons__sitemap__protocol_res',
                    path: '/dashboard/participant/protocol_result',
                    params: ['participant_id','protocolres_id'],
                    component: ProtocolRes
                },
                {
                    id: 'protocol',
                    name: 'commons__sitemap__protocol',
                    path: '/dashboard/participant/protocol',
                    params: ['participant_id'],
                    component: Protocol
                },
                {
                    id: 'edit_patient',
                    name: 'commons__sitemap__edit_patient',
                    path: '/dashboard/participant/edit',
                    params: ['participant_id'],
                    component: EditParticipant
                }
            ]
        }
    ]      
});

const SiteMap = {
    first: (f: (node: Node<viewInfo>) => boolean) : Node<viewInfo>|undefined => {
        
        let node = dashboardSiteViewsTree.first(f);

        if (node === undefined) {
            node = authSiteViewsTree.first(f);
        }

        return node;
    },

    get: (id: string) : Node<viewInfo>|undefined => {
        let node = dashboardSiteViewsTree.first((node: Node<viewInfo>) => node.model.id === id);

        if (node === undefined) {
            node = authSiteViewsTree.first((node: Node<viewInfo>) => node.model.id === id);
        }

        return node;
    },

    getFullURL: (to: string, params: Params = []) : string => {

        const node = SiteMap.get(to);

        if (node !== undefined) {
            if (params.length !== node.model.params.length) {
                throw new Error(
                    'Bad number of parameter for site view url:' +
                    node.model.name +
                    params.length.toString() +
                    '!==' +
                    node.model.params.length.toString()
                );
            }

            return [
                node.model.path,
                ...params.filter(p => p !== null).map(p => p!.toString()), // filter null parameters to exclude them from the final URL
                ''
            ].join('/')
        }
        throw new Error('Site view not found (' + to + ')');
    },

    getDashboardRoutingMap: () => dashboardSiteViewsTree
        .all(() => true)
        .map((node: Node<viewInfo>,idx) =>
            <Route
                key={idx}
                exact
                on
                path={[node.model.path, ...node.model.params].join('/:') + '/'}
                component={node.model.component}
            />
    ),
    
    getAuthRoutingMap: () => authSiteViewsTree
        .all(() => true)
        .map((node: Node<viewInfo>,idx) =>
            <Route
                key={idx}
                exact
                on
                path={[node.model.path, ...node.model.params].join('/:') + '/'}
                component={node.model.component}
            />
    )
};

export default SiteMap;
