import * as React from 'react'
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Apirest from "../../../Content/Apirest";

import {useContent} from '../../../Content/cms';

// Import store
import {StoreState} from "../../../store";

// Import interface
import {Examiner} from "../../interfaces";

// Import components
import DataFrame from "../../../Commons/Components/DataFrame";
import {RowSpinner, IconButton} from "../../../Commons/MultipleComponents";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import {Container, Row, Col, Alert} from "react-bootstrap";
import {SET_MANAGED_EXAMINERS} from "../../../store/admin/types";

interface ExaminersListProps {
    onEditExaminer: (examiner: Examiner) => void
}

const ExaminersList : React.FC<ExaminersListProps> = (props) => {

    let getContent = useContent();
    
    let [fetchingData, setFetchingData] = useState<boolean>(false);

    let examiners = useSelector<StoreState, Examiner[]>(state => state.managedDoctors);

    let dispatch = useDispatch();
    let setExaminers = (response: Examiner[]) => dispatch({
        type: SET_MANAGED_EXAMINERS,
        payload: response
    });

    useEffect(
        () => {
            setFetchingData(true);
            Apirest.get_examiners((response: Examiner[]) => {
                setExaminers(response);
                setFetchingData(false);
            });
        },
        [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const Examiners2DataFrame = (patients: Examiner[]) => patients.map(examiner => [
        <p>{examiner.first_name} {examiner.last_name}</p>,
        <IconButton
            text={getContent("home__participants_lists__edit_icon_tooltip") as string}
            icon={{icon:faPen, color:"#5CB6E7"}}
            onClick={() => props.onEditExaminer(examiner)}
        />
    ]);

    if (fetchingData) {
        return <Container className={'mt-3'}>
            <RowSpinner show={true} />
        </Container>
    }

    return (
        <Container className={'mt-3'}>
            <Row>
                <Col>
                    <h5 className={'p-2 text-primary '}>
                        {getContent('examiners_manager__examiners_list__title')}
                    </h5>
                </Col>
            </Row>
            
            {examiners.length === 0 && <Row>
                <Col>
                    <Alert variant={'warning mt-3'}>
                        {getContent('examiners_manager__examiners_list__empty_list')}
                    </Alert>
                </Col>
            </Row>}
            
            {examiners.length > 0 && <Row>
                <Col>
                    <DataFrame
                        header={[
                            getContent('examiners_manager__examiners_list__header__full_name'),
                            getContent('examiners_manager__examiners_list__header__edit')
                        ]}
                        className={'header-left header-bordered'}
                        pageSize={3}
                        columnWidths={[8,2]}
                        dataframe={Examiners2DataFrame(examiners)}
                    />
                </Col>
            </Row>}
            
        </Container>
    );
};

export default ExaminersList;
