import {
    SET_PARTICIPANTS,
    ADD_PARTICIPANTS,
    ParticipantsActionTypes,
    ParticipantsStoreState,
    ADD_OR_UPDATE_PARTICIPANT,
    CLEAR_PARTICIPANTS
} from "./types";

import {Participant} from "../../Dashboard/interfaces";
import { CLEAR_ALL } from "../actions_all";

const initialState: ParticipantsStoreState = [];

const participantComparator = (a: Participant, b: Participant) => {
    let name_a = a.last_name + ' ' + a.first_name;
    let name_b = b.last_name + ' ' + b.first_name;
  
    // If the patient have the same name, then sort by id
    if (name_a === name_b) {
        return b.id - a.id;
    }
  
    // If they have different names, then sort by names
    if (name_a < name_b) {
        return -1;
    }
  
    return 1;
  };

export function ParticipantsReducer(state:ParticipantsStoreState=initialState, action:ParticipantsActionTypes): ParticipantsStoreState {
    
    let newState:ParticipantsStoreState = state;
    
    switch (action.type) {
        case SET_PARTICIPANTS:
            newState = [...action.payload]
            break;
        
        case ADD_PARTICIPANTS:
            newState = [...newState, ...action.payload]
            break;

        case ADD_OR_UPDATE_PARTICIPANT:
            let newParticipant = action.payload as Participant;
            if (newState.find(p => p.id === newParticipant.id) === undefined) {
                newState = [...newState, newParticipant];
            }
            break;
        
        case CLEAR_ALL:
        case CLEAR_PARTICIPANTS:
            newState = initialState;
            break;

        default:
            break;
    }

    return newState.sort(participantComparator);
}