import RecordRTC from 'recordrtc';
import DeviceDetector from 'device-detector-js';

const deviceDetector = new DeviceDetector();
const device = deviceDetector.parse(navigator.userAgent);

// Alows TypeScript to find webkitAudioContext from Window as it's not yet suported
declare global {
    interface Window {
        webkitAudioContext: typeof AudioContext
    }
}

var media_device_config = {
    audio: {
        echoCancellation: false,
        // autoGainControl: true,
        // noiseSuppression: false,
        // sampleRate: 96000,
        // sampleSize: 16,
    }
}

const UNINITIALIZED_RECORDER = (
    'Recorder is not yet initialized.' +
    'Call getMediaRecorder() before start recording'
);

// Types declarations

type BlobCallback = (blob: Blob) => void;
type EmptyCallback = () => void;

export interface MicRecorderInterface {
    recorder: RecordRTC | null
    source: MediaStreamAudioSourceNode | null

    getMediaRecorder: () => void
    startRecording: () => void
    stopRecording: (callback:BlobCallback) => void
    pauseRecording: () => void
    resumeRecording: () => void
    destroy: () => void
}

class MicRecorder implements MicRecorderInterface {

    recorder: RecordRTC | null;
    source: MediaStreamAudioSourceNode | null;

    constructor(getMediaRecorder:boolean = true, callback: EmptyCallback = () => {}) {
        this.recorder = null;
        this.source = null;

        if (getMediaRecorder) {
            this.getMediaRecorder().then(callback);
        }
    }

    getMediaRecorder() {
        return new Promise<void>((resolve) => {
            function setup_media_recorder(this: MicRecorder, stream: MediaStream) {

                let recorderType;
                console.log("Device: ", device)


                if (device.client?.name !== 'Safari') {

                    console.log('[Sigmind Recorder] Using MediaStreamRecorder');
                    recorderType = RecordRTC.MediaStreamRecorder;

                    // TODO: Ver con facu para que sirve todo lo que sigue que no usamos en fit.
                    // TODO: Hay que ver si todo este case es solo para safari o todos los SO de iOS
                    let AudioContext = (window.AudioContext || window.webkitAudioContext)
                    let audioCtx = new AudioContext();

                    let analyser = audioCtx.createAnalyser();
                    analyser.minDecibels = -90;
                    analyser.maxDecibels = -10;
                    analyser.smoothingTimeConstant = 0.85;

                    let distortion = audioCtx.createWaveShaper();
                    let gainNode = audioCtx.createGain();
                    let biquadFilter = audioCtx.createBiquadFilter();
                    let convolver = audioCtx.createConvolver();

                    this.source = audioCtx.createMediaStreamSource(stream);
                    this.source.connect(distortion);

                    distortion.connect(biquadFilter);
                    biquadFilter.connect(gainNode);
                    convolver.connect(gainNode);
                    gainNode.connect(analyser);


                    this.recorder = new RecordRTC(stream, {
                        type: 'audio',
                        numberOfAudioChannels: 2,
                        checkForInactiveTracks: true,
                        recorderType: recorderType,
                        mimeType: "audio/webm"
                    });
    
                    
    
                } else {

                    console.log('[Sigmind Recorder] Safari device, using StereoAudioRecorder');
                    recorderType = RecordRTC.StereoAudioRecorder;

                    this.recorder = new RecordRTC(stream, {
                        type: 'audio',
                        numberOfAudioChannels: 2,
                        checkForInactiveTracks: true,
                        recorderType: recorderType,
                        mimeType: "audio/wav"
                    });
    
                }

           

                resolve();
            }

            navigator.mediaDevices.getUserMedia(
                media_device_config
            ).then(setup_media_recorder.bind(this));
        });
    }

    startRecording() {

        if (this.recorder === null) {
            throw UNINITIALIZED_RECORDER;
        }

        this.recorder.reset();
        this.recorder.startRecording();
    }

    stopRecording(callback:BlobCallback  = () => {}) {

        if (this.recorder === null) {
            throw new Error('Cannot stop a recorder if there is not yet a recorder instance');
        }

        this.recorder.stopRecording(() => callback(this.recorder!.getBlob()));
    }

    pauseRecording() {

        if (this.recorder === null) {
            throw UNINITIALIZED_RECORDER;
        }

        this.recorder.pauseRecording();
    }

    resumeRecording() {
        if (this.recorder === null) {
            throw UNINITIALIZED_RECORDER;
        }

        this.recorder.resumeRecording();
    }

    destroy() {
        if (this.recorder === null) {
            throw UNINITIALIZED_RECORDER;
        }

        this.recorder.destroy();
    }
}

export default MicRecorder;