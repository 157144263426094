import * as React from 'react'
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { boldRegexMatch, buildRegexExpession } from "../../Commons/Helpers";
import { useContent } from '../../Content/cms';

// Import store
import {StoreState} from "../../store";

// Import interface
import {Participant} from "../interfaces";
import {Row as DataFrameRow} from '../../Commons/Components/DataFrame/types';

// Import components
import DataFrame from "../../Commons/Components/DataFrame";
import {
    faFilter,
    faTimes
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "../../Commons/SiteMap/Link";
import Surveys from '../Surveys/Form';
import ProtocolLink from '../Protocol/protocolLink';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Spinner from 'react-bootstrap/Spinner';


interface FilteredParticipant {
    participant: Participant
    match: React.ReactNode
}

const ParticipantsList : React.FC = () => {

    let getContent = useContent();

    const mobiles = require('is-mobile');
    let mobile = mobiles();
    
    // Patient lists states
    let [participantsDataFrame, setParticipantsDataFrame] = useState<DataFrameRow[]>([]);
   

    // Filter patient states
    let [filterQuery, setFilterQuery] = useState<string>('');
    let [filteringParticipants, setFilteringParticipants] = useState<boolean>(false);
    let [filteredParticipants, setFilteredParticipants] = useState<DataFrameRow[]>([]);

    let participants = useSelector<StoreState, Participant[]>(state => state.participants);

    let filterTaskID = useRef<number|undefined>();
    let inputFilterRef = useRef<any>();

    const filterParticipants = (filter: string) => {

        if (filter === '') {
            setFilteredParticipants([]);
            setFilteringParticipants(false);
        }

        let regex = buildRegexExpession(encodeURI(filter).replace(' ',''));
        let newFilteredPatients = [];

        for(let participant of participants) {

            // Define value to match
            let values = [
                participant.code, 
                participant.first_name + ' ' + participant.last_name,
                participant.last_name + ' ' + participant.first_name
            ];

            // Test all value to check
            for (let value of values) {
                let [matches, formattedMatch]= boldRegexMatch(regex, value);
                if (matches) {
                    newFilteredPatients.push(
                        FilteredParticipant2Dataframe(
                            {participant: participant, match: formattedMatch}
                        )
                    );
                    break;
                }
            }
        }

        setFilteredParticipants(newFilteredPatients);
        setFilteringParticipants(false);
    };

    /***********************************************************/
    /* Components and functions determined by the doctor group */
    /***********************************************************/

    const [filteredParticipantHeader, participantsHeader] = getHeaders()


    const columnswidth = mobile === false ? [2,6,2,2]: [6,2,2];

    function Patient2DataFrameTell(patient: Participant): DataFrameRow {
        if (mobile === false){
        return [
            <Link to={'participant'} params={[patient.id]}>
                {patient.code}
            </Link>,
            <Link to={'participant'} params={[patient.id]}>
                {patient.last_name} {patient.first_name} 
            </Link>,
        <ProtocolLink participant_id={patient.id.toString()} onlyIcon align={'text-center'}/>,
            <Surveys participant_id={patient.id.toString()} onlyIcon align={'text-center'}/>
        ]} else {
            return [
                <Link to={'participant'} params={[patient.id]}>
                   <p className={'p-0 m-0'}>{patient.code}</p> <small className={'text-muted'}>{patient.last_name} {patient.first_name}</small> 
                </Link>,
            <ProtocolLink participant_id={patient.id.toString()} onlyIcon align={'text-center'}/>,
                <Surveys participant_id={patient.id.toString()} onlyIcon align={'text-center'}/>
            ]   
        };
    }


    function getHeaders() {
        const filteredPatientHeader = [
            <p className={'mb-2'}>{getContent('home__participants_lists__participant_list__header__match')}</p>,
            <p className={'mb-2'}>{getContent('home__participants_lists__participant_list__header__full_name')}</p>,
            <p className={'text-center mb-2'}>{getContent('home__participants_lists__participant_list__header__record')}</p>,
            <p className={'text-center mb-2'}>{getContent('home__participants_lists__participant_list__header__survey')}</p>
        ];

        const patientsHeader = mobile === false ? [
            <p className={'mb-2'}>{getContent('home__participants_lists__participant_list__header__code')}</p>,
            <p className={'mb-2'}>{getContent('home__participants_lists__participant_list__header__full_name')}</p>,
            <p className={'text-center mb-2'}>{getContent('home__participants_lists__participant_list__header__record')}</p>,
            <p className={'text-center mb-2'}>{getContent('home__participants_lists__participant_list__header__survey')}</p>
        ] : [
            <p className={'mb-2'}>{getContent('home__participants_lists__participant_list__header__code')}</p>,
            <p className={'text-center mb-2'}>{getContent('home__participants_lists__participant_list__header__record')}</p>,
            <p className={'text-center mb-2'}>{getContent('home__participants_lists__participant_list__header__survey')}</p>
        ];

        return [filteredPatientHeader, patientsHeader];
    }

    /***********************************************************/

    const delayedFilterPatients = (filterQuery: string) => {
        setFilterQuery(filterQuery);
        clearTimeout(filterTaskID.current);
        filterTaskID.current = (setTimeout(
            () => {

                setFilteringParticipants(true);
                setTimeout(() => filterParticipants(filterQuery),0);
            },
            25
        ) as unknown) as number;
    };

    const onClear = () => {
        setFilterQuery('');
        (inputFilterRef as any).current.value ='';
    };

    const FilteredParticipant2Dataframe = (patient: FilteredParticipant) : DataFrameRow => {
        let row = Patient2DataFrameTell(patient.participant);
        row[0] = (
            <Link to={'participant'} params={[patient.participant.id]} >
                {patient.match}
            </Link>
        );
        return row;
    };

    const Participant2Dataframe = (patients: Participant[]) : DataFrameRow[] => {
        return patients.map(patient => Patient2DataFrameTell(patient));
    }

    useEffect(
        () => {
            setParticipantsDataFrame(Participant2Dataframe(participants));
            },
        [participants, mobiles] // eslint-disable-line react-hooks/exhaustive-deps
    );

    if (participants.length === 0){
        return (
            <div className={"empty-patient-warnings viewPatient"}>
                <h5 className={'mt-4 p-2 text-primary '}>
                    {getContent("home__participants_lists__all_participants__title")}
                </h5>
                <Alert variant={'warning mt-3'}>
                    {getContent("home__participants_lists__alert_empty_list")}
                </Alert>
            </div>
        );
    }

    return (
        <Container className={'mt-3 '}>
            {participantsDataFrame.length > 0 && <Row className={'d-flex justify-content-end '}>
                <Col xs={12} md={5} className={'search-div'}>
                    <InputGroup className={"mb-2"}>
                        <InputGroup.Prepend >
                            <InputGroup.Text className={'bg-white'}>
                                {filteringParticipants ? <Spinner animation={'border'} size={'sm'}/> : <FontAwesomeIcon icon={faFilter}/>}
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            id={"inlineFormInputGroup"}
                            ref={inputFilterRef}
                            placeholder={getContent("home__participants_lists__filtered_participants__placeholder")}
                            onChange = {(event: any) => delayedFilterPatients(event.target.value)}
                        />
                        <InputGroup.Prepend  className={'filter-search-reset'}>
                            <button onClick={onClear} className={'bg-white no-border'}> <FontAwesomeIcon icon={faTimes} color={ filterQuery === '' ? '#fff' : '#495057'}/> </button>
                        </InputGroup.Prepend>
                    </InputGroup>
                </Col>
            </Row>}

            {filterQuery !== '' && <Row className={'viewPatient'}>
                <Col>
                    <h5 className={'p-2 text-primary'}>
                        {getContent("home__participants_lists__filtered_participants__title")}
                    </h5>
                    <DataFrame
                        header={filteredParticipantHeader}
                        className={'header-left header-bordered'}
                        columnWidths={columnswidth }
                        dataframe={filteredParticipants }
                        columnClassName={'text-center'}
                    />
                </Col>
            </Row>}

            {filterQuery === '' && <Row className={'viewPatient'}>
                <Col>
                    <h5 className={'p-2 text-primary'}>
                        {getContent("home__participants_lists__all_participants__title")}
                    </h5>
                    {participantsDataFrame.length > 0 && <DataFrame
                        header={participantsHeader}
                        className={'header-left header-bordered'}
                        columnWidths={columnswidth }
                        dataframe={participantsDataFrame}
                    />}

                    {/* If there is patients, but are all inactive, then show an alert */}
                    {participantsDataFrame.length === 0 && <Alert variant={'warning mt-3'}>
                        {getContent("home__participants_lists__alert_empty_list")}
                    </Alert>}
                </Col>
            </Row>}

        </Container>
    );
};

export default ParticipantsList;
