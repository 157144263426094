import * as React from "react";
import {getContentFunction} from "../../Content/cms";


export const registerParticipantSteps = (getContent: getContentFunction) => [
		{
			disableBeacon: true,
			target : '.thisTutorial' ,
			content: <div dangerouslySetInnerHTML={{__html: getContent("tutorials__register_participant__this_tutorial") as string}}></div>
		},
		{
	        target : '.completeName' ,
	        content: <div dangerouslySetInnerHTML={{__html: getContent("tutorials__register_participant__complete_name") as string}}></div>
	    },
	    {
	        target : '.registerPatient' ,
	        content: <p dangerouslySetInnerHTML={{__html : getContent("tutorials__register_participant__register_participant") as string}}></p>
	    },
	    {
	        target : '.viewPatient' ,
	        content: <p dangerouslySetInnerHTML={{__html: getContent("tutorials__register_participant__view_participant") as string}}></p>
	    }
	];
