import * as React from 'react';
import { useEffect } from 'react';
import { useContent } from '../../../Content/cms';
import { getEmptyValue, PopUpSuccess } from '../../../Commons/Helpers';
import { useFormFields } from '../../../Commons/Components/FormFields';

import Apirest from '../../../Content/Apirest';

import { Survey } from '../types';
import { FormField } from "../../../Commons/Components/FormFields/Field";
import { FormSubmitData } from "../../../Commons/Components/FormFields/types";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Section from '../../../Commons/Section';

type SurveyFormProps = Survey & {
    participant_id: string,
    disabled?: boolean,
    values?: FormSubmitData,
    description?: string
}

const SurveyForm : React.FC<SurveyFormProps> = (props) => {

    let {disabled, name, description, id} = props;


    const getContent = useContent();


    const {
        sections,
        setSections,
        setFormValues,
        submitHandler,
        onChange,
        state
    } = useFormFields();

    useEffect(
        () => {
            setSections(props.sections);
            setFormValues(props.values || {});
        }, [props.values, props.sections] // eslint-disable-line react-hooks/exhaustive-deps
    );

    function onSubmit(data: FormSubmitData) {
        let promise = new Promise<void>(
            (resolve, reject) => Apirest.send_survey_response(
                props.participant_id, id, data,
                () => {
                    PopUpSuccess(getContent("survey__form__send__success"));
                    resolve();
                },
                (jqXHR: JQueryXHR) => {
                    reject(jqXHR.responseJSON);
                }
            )
        );

        return promise;
    }

    return (
        <Form onSubmit={submitHandler(onSubmit)} className={'text-center'}>
            <Form.Group className={'text-left '}>
                <Container>
                    <Row className={'mt-3'}>
                        <Col>
                            <h3 className={'primary-section-header'}>
                                {name}
                            </h3>
                            <p className={"text-muted"}>{description}</p>
                        </Col>
                    </Row>
                    {sections.map(
                        (section, index) =>
                            <Section
                                key={index.toString()}
                                title={section.name}
                                subtitle={section.description}
                                className={'survey-section'}
                                headerClassName={'primary-section-header'}
                                bodyClassName={'primary-section-body px-0'}
                            >
                                {section.fields.map((field,idx) =>
                                    <Row key={field.name + '_' + idx + '_' + id} className={'mt-2'} >
                                        <Col>
                                            <FormField
                                                {...field}
                                                serverErrors={state.errors[field.name]}
                                                value={state.values[field.name] || getEmptyValue(field.type)}
                                                formValues={state.values}
                                                onChange={onChange}
                                                disabled={disabled}
                                                useCMS
                                            />
                                        </Col>
                                    </Row>
                                )}
                            </Section>
                    )}
                    {!disabled && <Row className={'mt-3'}>
                        <Col>
                            <Button type={'submit'} className={'btn btn-primary'}>
                                {!state.sending && getContent("survey__form__submit_btn")}
                                { state.sending && <Spinner size='sm' animation='border' />}
                            </Button>
                        </Col>
                    </Row>}
                </Container>
            </Form.Group>
        </Form>
    );
};

export default SurveyForm;
