import * as React from 'react';
import {useEffect, useState} from 'react';

// Import styles
import './index.css';

// Import interfaces
import {Row, DataFrameProps} from "./types";

// Import JSX components
import DataFramePagination from "./DataFramePagination";
import { Container, Spinner, Table } from 'react-bootstrap';
import {IconButton} from '../../MultipleComponents';
import { faTrash, faUndo } from "@fortawesome/free-solid-svg-icons";
import { useContent } from '../../../Content/cms';


const DataFrame : React.FC<DataFrameProps> = (props) => {

    let getContent = useContent();

    let [cpn, setCPN] = useState<number>(0); // cpn = Current Page Number
    let [deletingItem, setDeletingItem] = useState<number|undefined>();

    let {header, dataframe} = props;

    useEffect(() => {
        let pageSize = Math.max(1, props.pageSize || dataframe.length);
        setCPN(prev => Math.max(0, Math.min(prev,Math.ceil(dataframe.length / pageSize)-1)));
        setDeletingItem(undefined);
    }, [dataframe, props.pageSize]);

    const onRemove = (row: Row, index: number) => {
        if (props.onRemove) {
            setDeletingItem(index);
            props.onRemove(row, index, props.rows_data || []);
        }
    };

    const getRemoveIcon = (row: Row, index: number) => {

        if (deletingItem === index) {
            return <Spinner animation='border' size='sm' />;
        }

        if (deletingItem === undefined && (props.canRemove === undefined || props.canRemove(row, index, props.rows_data || []))) {
            return (
                <IconButton
                    onClick={() => onRemove(row, index)}
                    text={props.remove_tooltip_enabled_text || getContent('commons__components__dataframe__delete_tooltip_text')}
                    icon={{icon:faTrash,color:"#5CB6E7"}}
                />
            );
        }

        return (
            <IconButton
                text={props.remove_tooltip_disabled_text || getContent('commons__components__dataframe__disabled_delete_tooltip_text')}
                icon={{icon:faTrash,color:"#c1c5c8"}}
            />
        );
    };

    let pageSize = props.pageSize || dataframe.length;
    const pageOffset = cpn*pageSize;
    const totalPages = Math.ceil(dataframe.length / pageSize);
    let currentPage = dataframe.slice(pageOffset,pageOffset+pageSize);

    if(totalPages === 0) return null;

    return (
        <Container fluid >
            <Table responsive className={props.bordered || 'dark table borderless ' + props.className} striped={props.striped} >
                {header &&
                <thead >
                <tr className={'border-top-0'} >
                    {header.map((e, i) => <th className={'pb-0'} key={i} >{e}</th>) }
                    {props.onRemove && <th > </th>}
                    {props.onReset && <th > </th>}
                </tr>
                </thead>
                }
                <tbody >
                {currentPage.map((row, rdx) =>
                    <tr key={(pageOffset+rdx).toString() + '-row'} className={ props.clickeableRow ? 'cursor border-top-0' : 'border-top-0'}>
                        {row.map((e,cdx) =>
                            <td
                                key={(pageOffset+rdx).toString() + '-' + cdx.toString()} // unique identifier for each dataframe cell
                                className={props.columnWidths !== undefined ? 'col-width-' + props.columnWidths[cdx] : 'w-auto' }
                            >
                                {e}
                            </td>
                        )}
                        {props.onRemove &&
                        <td className={'col-width-1'}>
                            {getRemoveIcon(row, pageOffset+rdx)}
                        </td>
                        }
                        {props.onReset &&
                        <td className={'col-width-1'}>
                            <IconButton
                                onClick={() => {
                                    if (!props.modifiedRows || props.modifiedRows[rdx]) {
                                        props.onReset!(row, pageOffset+rdx, props.rows_data || [])
                                    }
                                }}
                                text={props.reset_tooltip_text || getContent('commons__components__dataframe__reset_tooltip_text')}
                                icon={{
                                    icon:faUndo,
                                    color: (!props.modifiedRows || props.modifiedRows[rdx]) ? "#5CB6E7" : "#DDDDDD"
                                }}
                            />
                        </td>
                        }
                    </tr>
                )}
                </tbody>
            </Table>
            <DataFramePagination
                cpn={cpn}
                totalPages={totalPages}
                setCPN={setCPN}
            />
        </Container>
    );
};

export default DataFrame;