import {Participant} from "../../Dashboard/interfaces";
import { AllActionsTypes } from "../actions_all";

// The global patients store state interface
export type ParticipantsStoreState = Participant[];

// The patients store action names
export const SET_PARTICIPANTS = 'SET_PARTICIPANTS';
export const ADD_PARTICIPANTS = 'ADD_PARTICIPANTS';
export const ADD_OR_UPDATE_PARTICIPANT = 'ADD_OR_UPDATE_PARTICIPANT';
export const CLEAR_PARTICIPANTS = 'CLEAR_PARTICIPANTS';

// The patients store actions interfaces
interface setParticipantsAction {
    type: typeof SET_PARTICIPANTS
    payload: Participant[]
}

interface addParticipantsAction {
    type: typeof ADD_PARTICIPANTS
    payload: Participant[]
}

interface addOrUpdateParticipantAction {
    type: typeof ADD_OR_UPDATE_PARTICIPANT
    payload: Participant
}

interface clearParticipantsAction {
    type: typeof CLEAR_PARTICIPANTS
}

// Export the final patients store action states as a single type
export type ParticipantsActionTypes =
    | setParticipantsAction
    | addParticipantsAction
    | addOrUpdateParticipantAction
    | clearParticipantsAction
    | AllActionsTypes;