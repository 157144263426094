import { useState } from "react";
import { useContent } from "../../../Content/cms";
import { PopUpError } from "../../Helpers";

import { FormSectionScheme, ServerFormError, FormSubmitData, FormFieldValue, FormTelField, FormMultiselectField } from "./types";

export function useFormFields(useCMS: boolean = true, clearFormOnSubmit: boolean = true) {

    let getContent = useContent();

    let [sections, setSectionsState] = useState<FormSectionScheme[]>([]);
    let [serverErrors, setServerErrors] = useState<ServerFormError>({});
    let [formValues, setFormValues] = useState<FormSubmitData>({});
    let [sending, setSending] = useState<boolean>(false);

    let setSections = (sections: FormSectionScheme[]) => {
  
        if (useCMS) {
            // Add form content from CMS
            for (let section of sections) {
                for (let field of section.fields) {
                    if (typeof field.label === 'string') {
                        field.label = getContent(field.label);
                    }

                    if (typeof field.placeholder === 'string') {
                        field.placeholder = getContent(field.placeholder);
                    }
                }
            }
        }

        setSectionsState(sections);
    }


    function submitHandler(handler: (data: FormSubmitData) => Promise<void>) { 
        return (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            event.stopPropagation();

            let data = {...formValues};

            for (let section of sections) {
                for (let field of section.fields) {
                    if (field.type === 'tel' && data[field.name] !== undefined) {
                        let val = data[field.name] as FormTelField;
                        if (val.number !== '') {
                            data[field.name] = val.country.dial_code + val.number;
                        } else {
                            delete data[field.name]; // Remove phone without number
                        }
                    }

                    if (field.type === 'checkbox') {
                        data[field.name] = data[field.name] || false;
                    }

                    if (field.type === 'select' && data[field.name] === 'other') {
                        data[field.name] = 'other: ' + data[field.name + '_other'];
                        delete data[field.name + '_other'];
                    }

                    if (field.type === 'multiselect' && data[field.name] !== undefined) {
                        let val = data[field.name] as FormMultiselectField;
                        if (val.indexOf('other') >= 0) {
                            val.push('other: ' + data[field.name + '_other'] as string);
                            delete data[field.name + '_other'];
                            val.splice(val.indexOf('other'), 1);
                        }
                    }
                }
            }

            if (Object.keys(data).length === 0) {
                PopUpError(getContent("form_hook__empty_data"));
                return;
            } else {
                setSending(true);
                handler(data).then(() => {
                    if (clearFormOnSubmit) {
                        setFormValues({});
                    }
                    setSending(false);
                }).catch((errors) => {
                    setServerErrors(errors);
                    if (errors.non_field_errors !== undefined) {
                        (errors.non_field_errors as string[]).forEach((error) => {
                            PopUpError(error);
                        });
                    }
                    setSending(false);
                });
            }

            
        }
    }

    function onChange(name: string, value: FormFieldValue) {
        setFormValues(prev => ({
            ...prev,
            [name]: value
        }));
    }

    let state = {
        values: formValues,
        errors: serverErrors,
        sending: sending
    };

    return {
        sections,
        setSections,
        setFormValues,
        submitHandler,
        onChange,
        state
    };
}