import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';

// Import JSX Components
import Apirest from "../../Content/Apirest";
import { Examiner, Participant } from "../interfaces";
import { useDispatch } from "react-redux";
import { SET_EXAMINER } from "../../store/examiner/types";
import { SET_PARTICIPANTS } from "../../store/participants/types";
import LoaderSpinner from "../../LoaderSpinner";

const DataLoader: React.FC = ({children}) => {

    let [examinerLoaded, setExaminerLoaded] = useState<boolean>(false);
    let [participantsLoaded, setParticipantsLoaded] = useState<boolean>(false);

    const dispatch = useDispatch();

    useEffect(() => {
        Apirest.get_examiner_info((response: Examiner) => {
            dispatch({
                type: SET_EXAMINER,
                payload: response
            });

            // Set doctor ID to analytics to be able to separate by doctor
            ReactGA.set({ userId: response.id });

            setExaminerLoaded(true);
        });

        Apirest.get_participants((response: Participant[]) => {
            dispatch({
                type: SET_PARTICIPANTS,
                payload: response
            });

            setParticipantsLoaded(true);
        })

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (!examinerLoaded || !participantsLoaded) {
        return <LoaderSpinner/>
    }

    return <>{children}</>;
};

export default DataLoader;
