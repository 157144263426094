import * as React from 'react';
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useContent } from "../../../Content/cms";
import { capitalize } from "../../../Commons/Helpers";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { ClearButton, Typeahead } from "react-bootstrap-typeahead";

import { Participant } from "../../interfaces";
import { StoreState } from "../../../store";

interface PatientSearchProps {
    onSelect: (selection: Participant[]) => void
    placeholder: string
    clearOnSelect?: boolean
    onClear?: () => void
}

const PatientSearch : React.FC<PatientSearchProps> = (props) => {

    let getContent = useContent();

    let [patientPrefix, setPatientPrefix] = useState<string>('');
    let [lastPatientVisited, setLastPatientVisited] = useState<Participant[]>([]);

    let patients = useSelector<StoreState, Participant[]>(state => state.participants);

    const inputRef = useRef<Typeahead<Participant>>(null);

    // Get the options to show
    let regex = new RegExp('(.)*' + patientPrefix.split('').join('(.)*') + '(.)*', 'i');
    let options = patientPrefix !== '' ?  patients.filter(
        patient => {
            return regex.test((patient.first_name + ' ' + patient.last_name)) || regex.test((patient.last_name + ' ' + patient.first_name))
        }
    ) : lastPatientVisited ;

    const onClear = () => {
        setPatientPrefix('');
        (inputRef as any).current.clear();

        if (props.onClear) {
            props.onClear();
        }
    };

    const handleInputChange = (value: string) => {
        setPatientPrefix(value);
    };

    const handleOnSelect = (selection: Participant[]) => {

        if (selection.length > 0) {
            setLastPatientVisited(visitedPatients => [...visitedPatients, selection[0]].slice(-5));
        }

        props.onSelect(selection);

        if (props.clearOnSelect) {
            onClear();
        }
    };

    return (
        <Typeahead
            id={'patient-search-typeahead'}
            ref={inputRef}
            inputProps={{className: 'pl-5'}}
            options={options.slice(0, 5)}
            placeholder={props.placeholder}
            emptyLabel={getContent("base__header__search_participant__empty_label") as string}
            labelKey={(patient: Participant) =>[
                ...patient.first_name.split(' '),
                ...patient.last_name.split(' ')
            ].map(n => capitalize(n)).join(' ')}
            onInputChange={handleInputChange}
            onChange={handleOnSelect}
        >
            <div className="lbt-aux">
                <FontAwesomeIcon icon={faSearch}/>
            </div>
            <div className="rbt-aux">
                { patientPrefix.length >= 1 ? <ClearButton onClick={onClear}/> : null}
            </div>
        </Typeahead>
    )
};

export default PatientSearch;
