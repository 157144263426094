import * as React from 'react';
import { useEffect} from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useContent } from "../../Content/cms";
import { PopUpSuccess, getEmptyValue, participant2Values } from "../../Commons/Helpers";
import { useFormFields } from '../../Commons/Components/FormFields';
import Apirest from "../../Content/Apirest";

// Import interface
import { Locale, Participant } from "../interfaces";
import { FormSectionScheme, FieldScheme, FormSubmitData } from '../../Commons/Components/FormFields/types';
import { ADD_OR_UPDATE_PARTICIPANT } from "../../store/participants/types";
import { StoreState } from '../../store';

// Import components
import ParticipantHeader from "../../Commons/Components/Participant/Header";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import {RowSpinner} from "../../Commons/MultipleComponents";
import {FormField} from "../../Commons/Components/FormFields/Field";

// Load form schema
import form_schema from './form_schema.json';

//import css
import "./index.css";

const EditParticipant : React.FC = () => {

    let getContent = useContent();

    let {participant_id} = useParams<{participant_id: string}>();
    let participant = useSelector(
        (state: StoreState) => state.participants.find(p => p.id.toString() === participant_id),
        shallowEqual
    );

    const mobiles = require('is-mobile');
    let mobile = mobiles();

    const {
        sections,
        setSections,
        setFormValues,
        submitHandler,
        onChange,
        state
    } = useFormFields(true, false);

    let dispatch = useDispatch();
    const updateParticipant = (patient: Participant) => dispatch({
        type: ADD_OR_UPDATE_PARTICIPANT,
        payload: patient
    });

    useEffect(
        () => {
            const new_sections : FormSectionScheme[] = [{
                name: '',
                description: '',
                fields: form_schema as FieldScheme[]
            }];

            let sex_field = new_sections[0].fields.find(field => field.name === 'sex');
            
            if (sex_field !== undefined && sex_field.option_values !== undefined) {
                sex_field.options = sex_field.option_values.map(
                    option => ({
                        value: option,
                        label: getContent('edit_participant__form__sex__options__' + option)
                    })
                )
            }
            
            // Add locales to the form
            Apirest.get_locales((response: Locale[]) => {
                for (let section of new_sections) {
                    for (let field of section.fields) {
                        if (field.name === 'locale' && field.options === undefined) {
                            field.options = response.map(locale => ({
                                value: locale.id.toString(),
                                label: locale.code
                            }));           
                        }
                    }
                }

                setSections(new_sections);
            });
        },
        [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    useEffect(
        () => {
            if (participant) {
                setFormValues(participant2Values(participant));
            }
        },
        [participant] // eslint-disable-line react-hooks/exhaustive-deps
    );

    function onSubmit(data: FormSubmitData) {
        let promise = new Promise<void>(
            (resolve, reject) => {


                Apirest.update_participant(
                    participant_id,
                    data,
                    (response: Participant) => {
                        updateParticipant(response);
        
                        PopUpSuccess(
                            getContent("edit_patient__server_response__edit_patient_info__success")
                        );

                        resolve();
                    },
                    (jqXHR: JQueryXHR) => {
                        reject(jqXHR.responseJSON);
                    }
                );
            }
        );

        return promise;
    }

    if (sections.length === 0) {
        return <RowSpinner show className='mt-3'/>;
    }

    console.log('FORM STATE', state);

    return (
        <Container fluid>
            <ParticipantHeader />
            <Container fluid className={'bg-light'}> 
                <Form onSubmit={submitHandler(onSubmit)}>
                <h3 className={'text-primary pt-3 px-3'}> {getContent('edit_participant__title')}</h3>
                    <Form.Group>
                        <Row >
                            {sections.map(({fields},rdx) =>
                                <Col key={rdx} className={'px-3 my-3 ' + (mobile ? null : 'form-container')}>
                                        {fields.map((field, fdx) =>
                                            <FormField
                                                key={fdx}
                                                {...field}
                                                serverErrors={state.errors[field.name]}
                                                value={state.values[field.name] || getEmptyValue(field.type)}
                                                onChange={onChange}
                                                useCMS={false /*TODO(Lao): customize error messages in backend and then use CMS*/}
                                            />
                                        )}
                                </Col>
                            )}
                        </Row>
                        <Row xs={12} sm={6} className={'justify-content-center'} >
                            <Button className={'m-3 col-9'} variant={'primary'} type={'submit'} block disabled={state.sending}>
                                {!state.sending && getContent('edit_participant__form__submit_button')}
                                {state.sending && <Spinner animation={'border'} size={'sm'} color={'#FFFFFF'} />}
                            </Button>
                        </Row>
                    </Form.Group>
                </Form>
            </Container>
        </Container>
    );
};

// <FormEditParticipant formData={formData} variant={'primary'} />

export default EditParticipant;
